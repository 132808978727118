import {productApis, visitStockApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import {useDispatch, useSelector} from "react-redux"
import {useEffect, useState} from "react"
import ErrorHandler from '../util/ErrorHandler'
import {Select} from 'antd'
import {setShowMap} from "../app/windowStateReducer";

const Stocks = ({visit}) => {

    const employment = useSelector(state => state.profile.myEmployment)
    const dispatch = useDispatch()

    const [products, setProducts] = useState()

    useEffect(() => {
        dispatch(setShowMap(false))
    }, [])

    useEffect(() => {
        if (employment) {
            productApis.listProductsForEmployee(employment.id).then(response => {
                setProducts(response.data)
            }).catch(error => {
                ErrorHandler(error, 'Failed to get the products !')
            })
        }
    }, [employment])

    return <DataTable
        defaultFilters={{
            visitId: visit.id,
        }}
        editable={true}
        itemName={'stock'}
        parent={visit}
        apis={visitStockApis}
        formToRestMapper={(item, record) => ({
            stock: {
                inventory: {
                    id: record.stock.inventory.id,
                },
                product: {
                    id: item.stock.product.id,
                },
                quantity: item.stock.quantity
            },
            visitId: record.visitId,
        })}
        columns={[
            {
                title: 'Product',
                dataIndex: ['stock', 'product', 'id'],
                type: 'select',
                items: products && products.map(item => <Select value={item.id}>{item.name}</Select>),
                editable: record => true,
                rules: [
                    {
                        required: true,
                        message: 'Product name is required !'
                    },
                ]
            },
            {
                title: 'Quantity',
                dataIndex: ['stock', 'quantity'],
                width: 100,
                align: 'right',
                type: 'number',
                editable: record => true,
                rules: [
                    {
                        required: true,
                        message: 'Quantity is required !',
                    },
                ],
            },
        ]}
        initialValues={{
            visitId: visit.id,
            stock: {
                inventory: {
                    id: visit.routeLocation.organization.inventory.id,
                }
            }
        }}
    />
}

export default Stocks