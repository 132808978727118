import {Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {productApis} from "../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ErrorHandler from "../util/ErrorHandler";

const InventoryTargetForm = ({old, parent}) => {

    const profile = useSelector(state => state.profile)
    const [products, setProducts] = useState();

    useEffect(() => {
        fetchProducts()
    }, [profile])

    const fetchProducts = () => {
        if (profile.myEmployment) {
            productApis.listProductsForEmployee(profile.myEmployment.id).then(response => {
                setProducts(response.data)
            }).catch(error => {
                ErrorHandler(error, 'Failed to get the product !')
            })
        }
        if (profile.superUser) {
            productApis.listApi({
                entity: {},
                pageable: {
                    page: 0,
                    size: 10000,
                    direction: 'DESC',
                    sort: 'lastModifiedDate',
                }
            }).then(response => {
                setProducts(response.data.content)
            }).catch(error => {
                ErrorHandler(error, 'Failed to load the product !')
                setProducts([])
            })
        }
    }

    return <>
        <Row>
            <Col xs={24}>
                <Form.Item name={['inventory', 'id']} label={'Inventory'} hidden={true}>
                    <Input/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item name={['product', 'id']} label={'Product'}>
                    <Select>
                        {products && products.map(product => <Select.Option
                            value={product.id}>{product.name}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item name={'period'} label={'Period'}>
                    <DatePicker.RangePicker picker={'date'} style={{width: '100%'}}/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item name={'target'} label={'Target'}>
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>
            </Col>
        </Row>
    </>
}

const InventoryTargetFormFunction = (old, parent) => <InventoryTargetForm old={old} parent={parent}/>

export default InventoryTargetFormFunction