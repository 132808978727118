import {useEffect, useState} from "react";
import ErrorHandler from "./ErrorHandler";

const useListLoader = (api, request, dependencies) => {
    const [loadingItems, setLoadingItems] = useState(false)
    const [items, setItems] = useState()

    useEffect(() => {
        setLoadingItems(true)
        api(request).then(response => {
            setItems(response.data)
            setLoadingItems(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to list items !')
            setLoadingItems(false)
        })
    }, [...dependencies])

    return [loadingItems, items]
}

export default useListLoader