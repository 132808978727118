import {Checkbox, Form, Input, Spin} from "antd"
import {listOrganizationClientsRecursiveClientsForOrganization} from "../api/api"
import ErrorHandler from "../util/ErrorHandler"
import {useEffect, useState} from "react"

const OrganizationRouteForm = ({old, parent}) => {
    const [LoadingLocations, setLoadingLocations] = useState(false)
    const [Locations, setLocations] = useState(undefined)

    useEffect(() => {
        fetchLocations()
    }, [])

    const fetchLocations = () => {
        setLoadingLocations(true)
        listOrganizationClientsRecursiveClientsForOrganization({
            page: 0,
            size: 1000
        }, parent.organization.id).then(response => {
            setLocations([
                ...response.data,
                parent.organization,
            ])
            setLoadingLocations(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed get locations !')
            setLoadingLocations(false)
        })
    }

    return (
        <Spin spinning={LoadingLocations}>
            <Form.Item label={"Route ID"} name={["route", "id"]}>
                <Input disabled={true}/>
            </Form.Item>
            <Form.Item label={"Name"} name={["name"]}>
                <Input disabled={false}/>
            </Form.Item>
            {/* <Form.Item
            label={'Location'}
            name={['location', 'id']}
        >
            <Select
                disabled={old}
                showSearch
                style={{width: '100%'}}
                placeholder="Select a location"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {
                    Locations && Locations.map(location => <Select.Option
                        value={location.location.id}>{location.name}</Select.Option>)
                }
            </Select>
        </Form.Item> */}
            <Form.Item
                label={"Modify Children"}
                name={"modifyChildren"}
                valuePropName={"checked"}
            >
                <Checkbox defaultChecked={false}/>
            </Form.Item>
        </Spin>
    );
}

const OrganizationRouteFormFunction = (old, parent) => <OrganizationRouteForm old={old} parent={parent}/>

export default OrganizationRouteFormFunction