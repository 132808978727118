import {employeementApis, listUnemployeed, titleApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import {useHistory} from "react-router-dom"
import {Select, Spin} from "antd";
import useListLoader from "../util/useListLoader";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setShowMap} from "../app/windowStateReducer";

const Employees = ({parent, visible}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [loadingUnemployeed, unemployeed] = useListLoader(listUnemployeed, {
        size: 1000
    }, [parent])
    const [loadingTitles, titles] = useListLoader(titleApis.listApi, undefined, [parent])

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    return <Spin spinning={loadingUnemployeed || loadingTitles}>
        <DataTable
            deletable={record => true}
            editable={true}
            defaultFilters={{
                organization: {
                    id: parent.id,
                },
            }}
            apis={employeementApis}
            browse={(record) => history.push(`/employee/${record.id}`)}
            itemName={'employee'}
            parent={{
                id: parent.id,
            }}
            columns={[
                {
                    title: 'Name',
                    dataIndex: ['userId'],
                    persistedDataIndex: ['user', 'firstName'],
                    browse: true,
                    type: 'select',
                    editable: record => record.temp,
                    items: unemployeed && unemployeed.content.map(item => <Select.Option
                        value={item.id}>{item.firstName}</Select.Option>),
                    render: (text, record, index) => <div>text</div>
                },
                {
                    title: 'Title',
                    dataIndex: ['title', 'id'],
                    type: 'select',
                    editable: record => true,
                    items: titles && titles.content.map(item => <Select.Option
                        value={item.id}>{item.name}</Select.Option>)
                },
                {
                    title: 'Email',
                    dataIndex: ['user', 'email'],
                },
            ]}
            restToFormMapper={employeement => ({
                ...employeement,
                name: employeement.user && `${employeement.user.firstName} ${employeement.user.lastName}`
            })}
        />
    </Spin>
}

export default Employees