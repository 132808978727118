import {
    LabelList,
    Legend,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    ResponsiveContainer,
    Tooltip,
    Text,
} from "recharts";
import {PureComponent} from 'react'

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (<Text x={x} y={y} width={150} textAnchor="middle">{payload.value}</Text>)
    }
}

const DataRadarChart = ({ structure, data, width, height }) => {

    const colors = [
        '#197BBD',
        '#5E6E79',
        '#137294',
        '#0D3E5F',
        '#8CBDDE'
    ]

    return <ResponsiveContainer width={width || '100%'} height={height || '100%'}>
        <RadarChart outerRadius={'70%'} data={data[0]}>
            <PolarGrid />
            <PolarAngleAxis dataKey='name' label={false} tick={<CustomizedAxisTick/>} />
            <PolarRadiusAxis angle={45} domain={[0, 150]} />
            {
                structure.items.map((item, index) => <Radar name={item.name || 'name'} dataKey={item.dataIndex || 'value'} fill={item.color || colors[index]} fillOpacity={0.6}>
                </Radar>)
            }
            <Tooltip contentStyle={{ borderRadius: 10, opacity: 0.8 }} />
        </RadarChart>
    </ResponsiveContainer>
}

export default DataRadarChart