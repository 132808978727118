import { Button, Calendar, Spin } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import ErrorHandler from "../util/ErrorHandler";
import ItemDrawer from "./ItemDrawer";
import { useHistory } from "react-router-dom";

const DataCalendar = ({
  restToFormMapper,
  formToRestMapper,
  drawerForm,
  browse,
  parent,
  list,
  postApi,
  putApi,
  deleteApi,
  apis,
  itemName,
  initialValues,
}) => {
  const [CalendarValue, setCalendarValue] = useState(moment());
  const history = useHistory();

  const [LoadingItems, setLoadingItems] = useState(false);
  const [Items, setItems] = useState({
    content: [],
  });

  const [ShowItemDrawer, setShowItemDrawer] = useState(false);
  const [SelectedItem, setSelectedItem] = useState();

  const [Old, setOld] = useState(false);

  const [InitialValues, setInitialValues] = useState(initialValues);

  useEffect(() => {
    setInitialValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    apis && apis.listApi && listItems();
  }, [CalendarValue, parent]);

  const listItems = () => {
    setLoadingItems(true);
    apis
      .listApi(
        {
          page: 0,
          size: 1000,
          monthStartDateTime: moment(CalendarValue)
            .startOf("month")
            .toISOString(),
        },
        parent && parent.id ? parent.id : undefined
      )
      .then((response) => {
        setItems(response.data);
        setLoadingItems(false);
      })
      .catch((error) => {
        ErrorHandler(error, "Failed to get events !");
        setLoadingItems(false);
      });
  };

  const dateCellRender = (value) => {
    let filtered = Items.content.filter(
      (item) =>
        moment(item.startDateTime).date() === value.date() &&
        value.month() === CalendarValue.month()
    );
    let components = [];

    components.push(<div>{value.date()}</div>);

    if (filtered && filtered.length > 0) {
      filtered
        .map((item) => (
          <Button
            type={"link"}
            size={"small"}
            onClick={() => {
              history.push(`/route/${item.route.id}`);
              // setSelectedItem(item)
              // setShowItemDrawer(true)
              // setOld(true)
            }}
          >
            {item.event.name}
          </Button>
        ))
        .forEach((item) => components.push(item));
    }

    return (
      <div
        style={{
          minHeight: 100,
          borderTop: "2px solid #eee",
          overflow: "scroll",
        }}
        onDoubleClick={() => {
          setInitialValues({
            ...InitialValues,
            parentEvent: {
              child: {
                startDateTime: [
                  moment(value)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0),
                  moment(value)
                    .set("hour", 24)
                    .set("minute", 0)
                    .set("second", 0),
                ],
              },
            },
          });
          postApi && setShowItemDrawer(true);
        }}
      >
        {components}
      </div>
    );
  };

  return (
    <Spin spinning={LoadingItems}>
      <ItemDrawer
        apis={apis}
        item={SelectedItem}
        parent={parent}
        browse={browse}
        old={Old}
        postApi={postApi}
        putApi={putApi}
        deleteApi={deleteApi}
        itemName={itemName ? itemName : "event"}
        onClose={(updated) => {
          updated && listItems();
          setOld(false);
          setSelectedItem(undefined);
          setShowItemDrawer(false);
          drawerForm && drawerForm.update && drawerForm.update();
        }}
        visible={ShowItemDrawer}
        initialValues={InitialValues}
        restToFormMapper={restToFormMapper}
        formToRestMapper={formToRestMapper}
        drawerFormUpdatable={drawerForm && drawerForm.updatable}
      >
        {drawerForm && drawerForm.render(Old, parent)}
      </ItemDrawer>
      <Calendar
        onPanelChange={(date, mode) => {
          setCalendarValue(date);
        }}
        // onChange={(date) => {
        //     setCalendarValue(date)
        // }}
        dateFullCellRender={dateCellRender}
        style={{
          margin: "0 10px",
        }}
        value={CalendarValue}
      />
    </Spin>
  );
};

export default DataCalendar;
