import MainPage from "../core/MainPage";
import DataTable from "../core/DataTable/DataTable";
import {
  organizationLevelApi,
} from "../api/api";
import { useEffect } from "react";
import { setShowMap } from "../app/windowStateReducer";
import { useDispatch } from "react-redux";

const OrganizationLevels = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowMap(false));
  }, []);

  return (
    <MainPage
      headerProps={{
        title: "Organization Levels",
      }}
    >
      <DataTable
        deletable={(record) => true}
        editable={true}
        drag={true}
        apis={organizationLevelApi}
        restToFormMapper={(data) => {
          return {
            ...data,
            icon: data.icon && [
              {
                data_url: data.icon,
              },
            ],
          };

        }}
        formToRestMapper={(data) => ({
          ...data,
          icon:
            data.icon && data.icon.length > 0
              ? data.icon[0].data_url
              : undefined,
        })}
        columns={[
          {
            dataIndex: ["name"],
            title: "Name",
            editable: (record) => true,
            type: "text",
          },
          {
            dataIndex: ["icon"],
            title: "Icon",
            editable: (record) => true,
            type: "icon",
          },
        ]}
        itemName={"organization_level"}
      />
    </MainPage>
  );
};

export default OrganizationLevels;
