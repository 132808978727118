import {
    AreaChartOutlined,
    BankOutlined,
    HomeOutlined,
    LogoutOutlined,
    SearchOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    UserOutlined
} from '@ant-design/icons'
import { Input, Menu } from 'antd'
import { useHistory } from 'react-router-dom'
import { BaseUrl, RealmName, organizationLevelApi, postLogout } from '../api/api'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setLevels } from '../app/markerReducer'

const MainMenu = () => {
    const history = useHistory()
    const user = useSelector(state => state.profile.user)
    const dispatch = useDispatch()

    useEffect(() => {
        organizationLevelApi.listApi({
            pageable: {
                page: 0,
                size: 1000,
                direction: 'DESC',
                sort: 'lastModifiedDate',
            },
            entity: {},
        }).then(response => {
            dispatch(setLevels(response.data.content))
        })
    }, [])

    return (
        <>
            <div
                style={{
                    backgroundImage: 'url("/tokyo-cement-logo.png")',
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    // backgroundSize: 'cover',
                    height: 40,
                    margin: 10,
                }}
            />
            <Input.Search
                style={{
                    margin: 10,
                    width: "calc(100% - 20px)",
                }}
            />
            <Menu mode='inline'>
                <Menu.Item icon={<HomeOutlined />} onClick={() => history.push('/')}>Home</Menu.Item>
                <Menu.Item icon={<BankOutlined />}
                    onClick={() => history.push('/organization')}>Organizations</Menu.Item>
                <Menu.SubMenu icon={<SettingOutlined />} title='Settings'>
                    <Menu.Item onClick={() => history.push('/settings/organization-levels')}
                        icon={<UnorderedListOutlined />}>Organization Levels</Menu.Item>
                    <Menu.Item onClick={() => history.push('/settings/employee-levels')}
                        icon={<UnorderedListOutlined />}>Employee
                        Levels</Menu.Item>
                    <Menu.Item onClick={() => history.push('/settings/product-categories')}
                        icon={<UnorderedListOutlined />}>Product
                        Categories</Menu.Item>
                </Menu.SubMenu>
                {user && <Menu.Item
                    icon={<UserOutlined />}
                    onClick={() => window.location.href = `${BaseUrl}/auth/realms/${RealmName}/account/`}
                >
                    {user.name}
                </Menu.Item>}
            </Menu>
        </>
    );
}

export default MainMenu