import moment from "moment"
import {useHistory} from "react-router-dom"
import {orderApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setShowMap} from "../app/windowStateReducer";

const Orders = ({parent, updatable, visible}) => {

    const history = useHistory()

    const dispatch = useDispatch()

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    return <DataTable
        editable={true}
        deletable={record => record.orderStatus === 'DRAFT'}
        browse={record => history.push(`/orders/${record.id}`)}
        itemName={'order'}
        parent={parent}
        defaultFilters={{
            toInventory: {
                id: parent.inventory.id,
            },
        }}
        apis={orderApis}
        columns={[
            {
                title: 'Date',
                dataIndex: ['createdDate'],
                render: (text, record, index) => moment(record.createdDate).format('MMMM Do YYYY'),
                browse: record => history.push(`/orders/${record.id}`),
            },
            {
                title: 'Order Status',
                dataIndex: ['orderStatus'],
            },
            {
                title: 'Dispatch Status',
                dataIndex: ['dispatchStatus'],
            },
            {
                title: 'Delivery Status',
                dataIndex: ['deliveryStatus'],
            },
        ]}
    />
}

export default Orders