import { Brush, Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Label, Text } from "recharts";
import { PureComponent } from "react";
import { colors } from "./DataPieChart";

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
        );
    }
}

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (<Text x={x} y={y} width={100} textAnchor="end" angle={-90} verticalAnchor="start">{payload.value}</Text>)
    }
}

const DataBarChart = ({ xAxisLabel, yAxisLabel, xAxisDataIndex, structure, stacked, data, multiYAxis, height, width }) => {

    return <ResponsiveContainer
        debounce={2}
        width={width || '100%'}
        height={height || '100%'}
    >
        <BarChart
            data={data[0]}
            margin={{
                bottom: 100,
            }}
        >
            <defs>
                {
                    colors.map((color, index) => <linearGradient id={`${index}-color`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={color} stopOpacity={0.2} />
                    </linearGradient>)
                }
            </defs>
            <XAxis axisLine={false} interval={0} dataKey={xAxisDataIndex || 'name'} tickLine={false} tick={<CustomizedAxisTick />} />
            {/* {
                multiYAxis ? structure.items.map((item, index) => <YAxis
                    yAxisId={`${index}-id`}
                    orientation={index === 1 ? 'right' : 'left'}
                    label={{ value: item.dataIndex, angle: -90, position: index === 1 ? 'insideRight' : 'insideLeft' }}
                />) : <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }} />
            } */}
            <Legend verticalAlign="bottom" align="center" height={36} iconType={'square'} layout="horizontal" wrapperStyle={{bottom: 20}} />
            <Tooltip contentStyle={{ borderRadius: 10, opacity: 0.8 }} cursor={{fill: '#fff'}}/>
            {
                structure.items.map((item, index) => <Bar stackId={stacked && 'a'} yAxisId={multiYAxis && `${index}-id`} fill={item.color ? item.color : `url(#${index}-color)`} dataKey={item.dataIndex} label={{ angle: -90 }} radius={[10, 10, 10, 10]} background={{ fill: '#eee', radius: [10, 10, 10, 10]}} />)
            }
        </BarChart>
    </ResponsiveContainer>
}

export default DataBarChart