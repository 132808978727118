import DataTable from "../core/DataTable/DataTable";
import {employeeTargetApis} from "../api/api";
import {useHistory} from "react-router-dom";
import EmployeeTargetFormFunction from "./EmployeeTargetFormFunction";

const EmployeeTargets = ({visible, parent}) => {
    const history = useHistory()

    return <DataTable
        itemName={'employee_target'}
        browse={record => history.push(`/employee-targets/employee/${parent.id}/product/${record.id}`)}
        parent={parent}
        editable={true}
        deletable={record => true}
        apis={{
            postApi: employeeTargetApis.updateTargets,
            listApi: employeeTargetApis.getDistinct,
        }}
        columns={[
            {
                title: 'Product',
                dataIndex: ['name'],
            },
        ]}
        defaultFilters={{
            employeeId: parent.id
        }}
        formToRestMapper={data => ({
            ...data,
            startDate: data.period[0].hour(0).minute(0).second(0).millisecond(0),
            endDate: data.period[1].hour(0).minute(0).second(0).millisecond(0),
            employeeId: parent.id,
        })}
        drawerForm={{
            render: EmployeeTargetFormFunction,
            updatable: true,
        }}
    />
}

export default EmployeeTargets