import moment from "moment"
import { productApis, stockApis } from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ErrorHandler from "../util/ErrorHandler";
import { Select } from "antd";
import { setShowMap } from "../app/windowStateReducer";

const Stocks = ({ parent, visible }) => {
    const employeement = useSelector(state => state.profile.myEmployment)
    const dispatch = useDispatch()

    const [products, setProducts] = useState();

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    useEffect(() => {
        if (employeement)
            productApis.listProductsForEmployee(employeement.id).then(response => {
                setProducts(response.data)
            }).catch(error => {
                ErrorHandler(error, 'Failed to load the products !')
            })
    }, [employeement])

    return <DataTable
        itemName={'stock'}
        editable={true}
        parent={parent}
        apis={stockApis}
        defaultFilters={{
            inventory: {
                id: parent.inventory.id,
            },
        }}
        columns={[
            {
                title: 'Date',
                dataIndex: ['lastModifiedDate'],
                ellipsis: true,
            },
            {
                title: 'Product',
                type: 'select',
                dataIndex: ['product', 'id'],
                persistedDataIndex: ['product', 'name'],
                editable: record => record.temp,
                items: products && products.map(item => <Select.Option
                    value={item.id}>{item.name}</Select.Option>)
            },
            {
                align: 'right',
                title: 'Quantity',
                dataIndex: ['quantity'],
                width: 100,
                type: 'number',
                editable: record => !parent.inventory.managed,
            },
            {
                align: 'right',
                title: 'Reserved',
                dataIndex: ['reserved'],
                width: 100,
                type: 'number',
                editable: record => false,
            },
        ]}
        formToRestMapper={data => ({
            ...data,
            lastModifiedDate: moment(data.lastModifiedDate)
        })}
        restToTableMapper={data => ({
            ...data,
            lastModifiedDate: moment(data.lastModifiedDate).format('MMMM Do YYYY')
        })}
    />
}

export default Stocks