import {Col, Form, Input, Row, Select, Spin} from 'antd'
import {useEffect, useState} from 'react'
import {listUnemployeed, titleApis} from '../api/api'
import ErrorHandler from "../util/ErrorHandler";

const EmployeeForm = ({old}) => {

    const [LoadingUnemployeed, setLoadingUnemployeed] = useState(false)
    const [Unemployeed, setUnemployeed] = useState([])

    const [LoadingTitles, setLoadingTitles] = useState(false)
    const [Titles, setTitles] = useState()

    useEffect(() => {
        searchUnemployeed()
        fetchTitles()
    }, [])

    const searchUnemployeed = () => {
        setLoadingUnemployeed(true)
        listUnemployeed({
            page: 0,
            sortBy: 'lastModifiedDate',
            sortDirection: 'descend'
        }).then(response => {
            setUnemployeed(response.data.content)
            setLoadingUnemployeed(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get unemployed users !')
            setLoadingUnemployeed(false)
        })
    }

    const fetchTitles = () => {
        setLoadingTitles(true)
        titleApis.listApi({
            pageable: {
                page: 0,
                size: 1000,
                sort: 'lastModifiedBy',
                direction: 'DESC',
            },
            entity: {},
        }).then(response => {
            setTitles(response.data.content)
            setLoadingTitles(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get unemployed users !')
            setLoadingTitles(false)
        })
    }

    return <Spin spinning={LoadingUnemployeed || LoadingTitles}>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={old ? 'Organization Name' : 'Organization ID'}
                    name={old ? ['organization', 'name'] : ['organization', 'id']}
                    rules={[
                        {
                            required: true,
                            message: 'Organization is required !'
                        }
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={'Name'}
                    name={old ? 'name' : 'userId'}
                    rules={[
                        {
                            required: true,
                            message: 'Name is required !'
                        }
                    ]}
                >
                    {old ? <Input disabled={true} style={{width: '100%'}}/> : <Select
                        showSearch
                        style={{width: '100%'}}
                        placeholder="Select an unemployeed person"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            Unemployeed && Unemployeed.map(item => <Select.Option
                                value={item.id}>{`${item.firstName} ${item.lastName}`}</Select.Option>)
                        }
                    </Select>}
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={'Title'}
                    name={['title', 'id']}
                    rules={[
                        {
                            required: true,
                            message: 'Title is required !'
                        }
                    ]}
                >
                    <Select
                        showSearch
                        style={{width: '100%'}}
                        placeholder="Select a title"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            Titles && Titles.map(item => <Select.Option value={item.id}>{item.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        {old && <>
            <Row>
                <Col xs={24}>
                    <Form.Item
                        label={'Email'}
                        name={['user', 'email']}
                    >
                        <Input disabled={true} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <Form.Item
                        label={'Employeement Date'}
                        name={['createdDate']}
                    >
                        <Input disabled={true} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
        </>}
    </Spin>
}

const EmployeeFormFunction = (old) => <EmployeeForm old={old}/>

export default EmployeeFormFunction