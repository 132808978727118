import {Col, Form, Input, Row, Select, Spin} from "antd"
import {useEffect, useState} from "react"
import {listAssignedOrganizationsForEmployeeRecursively} from "../api/api"
import ErrorHandler from "../util/ErrorHandler"

const RouteLocationForm = ({old, parent}) => {

    const [LoadingItems, setLoadingItems] = useState(false)
    const [Items, setItems] = useState({
        content: [],
    })

    useEffect(() => {
        fetchRouteLocations()
    }, [])

    const fetchRouteLocations = () => {
        setLoadingItems(true)
        listAssignedOrganizationsForEmployeeRecursively({
            page: 0,
            size: 1000,
        }, parent.employeementId).then(response => {
            setItems(response.data)
            setLoadingItems(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get route locations !')
            setLoadingItems(false)
        })
    }

    return <Spin spinning={LoadingItems}>
        <Row>
            <Col xs={24}>
                <Form.Item
                    name={['route', 'id']}
                    label={'Route ID'}
                >
                    <Input disabled={true}/>
                </Form.Item>
            </Col>
        </Row>
        {Items && <Row>
            <Col xs={24}>
                <Form.Item
                    name={['location', 'id']}
                    label={'Organization'}
                >
                    <Select
                        disabled={old}
                        showSearch
                        style={{width: '100%'}}
                        placeholder="Select a location"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {Items.content.map(item => <Select.Option value={item.location.id}>{item.name}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>}
    </Spin>
}

const RouteLocationFormFunction = (old, parent) => <RouteLocationForm old={old} parent={parent}/>

export default RouteLocationFormFunction