import {Button} from 'antd'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {cancelDelivery, deliverDelivery, dispatchDelivery, getDelivery, readyDelivery, rejectDelivery} from '../api/api'
import MainPage from '../core/MainPage'
import PageDescriptions from '../core/PageDescriptions'
import TabSet from '../core/TabSet'
import ErrorHandler from '../util/ErrorHandler'
import DeliveryItems from './DeliveryItems'

const Delivery = () => {
    const {id} = useParams()

    const [Item, setItem] = useState()

    useEffect(() => {
        fetchDelivery()
    }, [])

    const fetchDelivery = () => {
        getDelivery(id).then(response => {
            setItem(response.data)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get the delivery !')
        })
    }

    const buildExtras = () => {
        if (!Item) {
            return
        }

        let extras = []
        if (Item.status === 'DRAFT') {
            extras.push(<Button onClick={() => {
                readyDelivery(Item.id).then(response => fetchDelivery()).catch(error => ErrorHandler(error, 'Failed to ready the delivery !'))
            }}>READY</Button>)
        } else if (Item.status === 'READY') {
            extras.push(<Button onClick={() => {
                    cancelDelivery(Item.id).then(response => fetchDelivery()).catch(error => ErrorHandler(error, 'Failed to cancel the delivery !'))
                }}>CANCEL</Button>,
                <Button onClick={() => {
                    dispatchDelivery(Item.id).then(response => fetchDelivery()).catch(error => ErrorHandler(error, 'Failed to dispatch the delivery !'))
                }}>DISPATCH</Button>)
        } else if (Item.status === 'DISPATCHED') {
            extras.push(<Button onClick={() => {
                    deliverDelivery(Item.id).then(response => fetchDelivery()).catch(error => ErrorHandler(error, 'Failed to deliver the delivery !'))
                }}>DELIVER</Button>,
                <Button onClick={() => {
                    rejectDelivery(Item.id).then(response => fetchDelivery()).catch(error => ErrorHandler(error, 'Failed to reject the delivery !'))
                }}>REJECT</Button>)
        }

        return extras
    }

    return (
        <MainPage
            headerProps={{
                title:
                    Item &&
                    `Delivery from ${Item.fromOrganization.name} to ${Item.toOrganization.name}`,
                extra: buildExtras(),
            }}
            headerContent={
                Item && (
                    <PageDescriptions
                        items={[
                            {
                                label: "Status",
                                value: Item.status,
                            },
                            {
                                label: 'From',
                                value: Item.fromOrganization.name,
                                url: `/organization/${Item.fromOrganization.id}`
                            },
                            {
                                label: 'To',
                                value: Item.toOrganization.name,
                                url: `/organization/${Item.toOrganization.id}`
                            },
                        ]}
                    />
                )
            }
            item={Item}
        >
            {Item && (
                <TabSet
                    routes={{
                        default: 'items',
                        routes: [
                            {
                                permission: 'stock_transfer_item:list',
                                name: 'Items',
                                dataIndex: 'items',
                                component: visible => <DeliveryItems visible={visible} parent={Item}/>
                            },
                        ],
                    }}
                />
            )}
        </MainPage>
    );
}

export default Delivery