import MainPage from '../core/MainPage';
import DataLineChart from '../core/Analytics/DataLineChart';
import { useParams } from 'react-router-dom';
import {
  employeeTargetApis,
  inventoryApis,
  inventoryTargetApis,
  productApis,
} from '../api/api';
import moment from 'moment';
import ChartCard from '../core/Analytics/ChartCard';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  notification,
  Select,
  Table,
} from 'antd';
import { EditTwoTone, ExportOutlined } from '@ant-design/icons';
import { setShowMap } from '../app/windowStateReducer';
import { useDispatch } from 'react-redux';
import PageDescriptions from '../core/PageDescriptions';
import fileDownload from 'js-file-download';
import NumberFormat from 'react-number-format';

const InventoryTarget = () => {
  const { inventoryId, productId } = useParams();
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
  const [endDate, setEndDate] = useState(moment().add(1, 'months'));
  const [type, setType] = useState('week');
  const [targets, setTargets] = useState();
  const dispatch = useDispatch();
  const [product, setProduct] = useState();
  const [inventory, setInventory] = useState();

  useEffect(() => {
    dispatch(setShowMap(false));
  }, []);

  useEffect(() => {
    if (inventoryId)
      inventoryApis.getApi(inventoryId).then((response) => {
        setInventory(response.data);
      });
  }, [inventoryId]);

  useEffect(() => {
    if (productId)
      productApis.getApi(productId).then((response) => {
        setProduct(response.data);
      });
  }, [productId]);

  useEffect(() => {
    fetchTargets();
  }, [inventoryId, productId, startDate, endDate, type]);

  const fetchTargets = () => {
    inventoryTargetApis.getBetween(generateTargetRequest()).then((response) => {
      setTargets(response.data);
    });
  };

  const [visualizationForm] = Form.useForm();
  const [updateForm] = Form.useForm();

  const [selectionType, setSelectionType] = useState('week');

  const generateTargetRequest = () => ({
    inventory: {
      id: inventoryId,
    },
    product: {
      id: productId,
    },
    startDate:
      type === 'month'
        ? startDate.date(1).hour(0).minute(0).second(0).millisecond(0)
        : startDate.hour(0).minute(0).second(0).millisecond(0),
    endDate:
      type === 'month'
        ? endDate.date(1).hour(0).minute(0).second(0).millisecond(0)
        : endDate.hour(0).minute(0).second(0).millisecond(0),
    type: type,
  });

  return (
    <MainPage
      headerProps={{
        title: 'Target',
      }}
      headerContent={
        <PageDescriptions
          items={[
            inventory && {
              label: 'Organization',
              value: inventory.organization.name,
              url: `/organization/${inventory.organization.id}`,
            },
            product && {
              label: 'Product',
              value: product.name,
              url: `/product-category/${product.category.id}`,
            },
          ]}
        />
      }
    >
      <ChartCard
        onOk={() => {
          visualizationForm.submit();
          fetchTargets();
        }}
        title={'Target'}
        height={400}
        settingsForm={
          <>
            <Divider>Visualize Targets</Divider>
            <Select
              onChange={(value) => setSelectionType(value)}
              value={selectionType}
              style={{ width: 'calc(100% - 20px)', margin: 20 }}
            >
              <Select.Option value={'date'}>Daily</Select.Option>
              <Select.Option value={'month'}>Monthly</Select.Option>
              <Select.Option value={'quarter'}>Quarterly</Select.Option>
              <Select.Option value={'year'}>Yearly</Select.Option>
            </Select>
            <Form
              colon={false}
              initialValues={{
                period: [startDate, endDate],
                startDate,
                endDate,
                type,
              }}
              form={visualizationForm}
              labelCol={{
                span: 5,
              }}
              onFinish={(data) => {
                setStartDate(data.period[0]);
                setEndDate(data.period[1]);
                setType(data.type);
              }}
            >
              <Form.Item name={'period'} label={'Period'}>
                <DatePicker.RangePicker
                  picker={selectionType}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item name={'type'} label={'Aggregation'}>
                <Select>
                  <Select.Option value={'day'}>Daily</Select.Option>
                  <Select.Option value={'week'}>Weekly</Select.Option>
                  <Select.Option value={'month'}>Monthly</Select.Option>
                  <Select.Option value={'year'}>Yearly</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <Divider>Update Targets</Divider>
            <Form
              colon={false}
              initialValues={{
                period: [startDate, endDate],
                startDate,
                endDate,
                type,
              }}
              form={updateForm}
              labelCol={{
                span: 5,
              }}
              onFinish={(data) => {
                inventoryTargetApis
                  .updateTargets({
                    startDate: data.period[0]
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .millisecond(0),
                    endDate: data.period[1]
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .millisecond(0),
                    target: data.target,
                    inventory: {
                      id: inventoryId,
                    },
                    product: {
                      id: productId,
                    },
                  })
                  .then((response) => {
                    notification['success']({
                      message: 'Successfully updated the targets !',
                    });
                  });
              }}
            >
              <Form.Item name={'period'} label={'Period'}>
                <DatePicker.RangePicker
                  picker={selectionType}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item name={'target'} label={'Target'}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 5,
                }}
              >
                <Button
                  icon={<EditTwoTone />}
                  onClick={() => updateForm.submit()}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </>
        }
      >
        {targets && (
          <DataLineChart
            xAxisLabel={'Date'}
            yAxisLabel={'Amount'}
            data={[
              ...targets.map((target) => ({
                ...target,
                name:
                  type === 'month'
                    ? moment(target.name).format('MMMM YYYY')
                    : type === 'year'
                    ? moment(target.name).format('YYYY')
                    : moment(target.name).format('MMMM DD'),
              })),
            ]}
            structure={{
              items: [
                {
                  dataIndex: 'target',
                  color: 'red',
                },
                {
                  dataIndex: 'achieved',
                  color: 'green',
                },
              ],
            }}
          />
        )}
      </ChartCard>
      <Card
        extra={[
          <Button
            icon={<ExportOutlined />}
            onClick={() => {
              inventoryTargetApis
                .exportTargets(generateTargetRequest())
                .then((response) => {
                  fileDownload(response.data, 'export-inventory-targets.csv');
                });
            }}
          />,
        ]}
      >
        <Table
          pagination={false}
          columns={[
            {
              title: 'Date',
              dataIndex: ['name'],
              render: (text) => <span>{moment(text).format('ll')}</span>,
            },
            {
              title: 'Organization Target',
              dataIndex: ['target'],
              align: 'right',
              render: (text) => (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={text}
                />
              ),
            },
            {
              title: 'Achieved',
              dataIndex: ['achieved'],
              align: 'right',
              render: (text) => (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={text}
                />
              ),
            },
            {
              title: 'Percentage',
              dataIndex: ['percentage'],
              align: 'right',
              render: (text) => (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={text}
                />
              ),
            },
          ]}
          dataSource={targets}
        />
      </Card>
    </MainPage>
  );
};

export default InventoryTarget;
