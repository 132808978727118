import {Col, Divider, Form, InputNumber, Row, Select, Spin} from "antd"
import {useEffect, useState} from "react";
import {listForDelivery, productApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import ErrorHandler from "../util/ErrorHandler";
import {useSelector} from "react-redux";

const DeliveryItemDrawerForm = ({old, parent}) => {
    const employeement = useSelector(state => state.profile.myEmployment)
    const [products, setProducts] = useState();

    useEffect(() => {
        if (employeement)
            productApis.listProductsForEmployee(employeement.id).then(response => {
                setProducts(response.data)
            }).catch(error => {
                ErrorHandler(error, 'Failed to load the products !')
            })
    }, [employeement])

    return <Spin spinning={false}>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={'For Order Items'}
                    name={'selected'}
                >
                    <DataTable
                        disableActions={true}
                        parent={{
                            id: parent.toInventory.id
                        }}
                        apis={{
                            listApi: listForDelivery,
                        }}
                        columns={[
                            {
                                title: 'Product',
                                dataIndex: ['product', 'name']
                            },
                            {
                                title: 'Ordered',
                                dataIndex: ['orderedQuantity']
                            },
                            {
                                title: 'Dispatched',
                                dataIndex: ['dispatchedQuantity']
                            },
                        ]}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Divider>Custom</Divider>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={'Product'}
                    name={['custom', 'product', 'id']}
                >
                    <Select>
                        {
                            products && products.map(product => <Select.Option
                                value={product.id}>{product.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={'Quantity'}
                    name={['custom', 'quantity']}
                >
                    <InputNumber
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
            </Col>
        </Row>
    </Spin>
}

const DeliveryItemDrawerFormFunction = (old, parent) => <DeliveryItemDrawerForm old={old} parent={parent}/>

export default DeliveryItemDrawerFormFunction