import moment from "moment"
import {deliveryApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import StockTransferFormFunction from "./StockTransferFormFunction"
import {useHistory} from "react-router-dom"
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setShowMap} from "../app/windowStateReducer";

const Received = ({parent, visible}) => {

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])


    return <DataTable
        browse={record => history.push(`/deliveries/${record.id}`)}
        itemName={'delivery'}
        parent={parent}
        defaultFilters={{
            toInventory: {
                id: parent.inventory.id,
            },
        }}
        apis={{
            ...deliveryApis,
            listApi: request => deliveryApis.listApi({
                ...request,
                entity: {
                    ...request.entity,
                    status: 'DELIVERED',
                }
            })
        }}
        drawerForm={{
            render: StockTransferFormFunction
        }}
        columns={[
            {
                title: 'Date',
                dataIndex: ['createdDate'],
                render: (text, record, index) => moment(record.createdDate).format('MMMM Do YYYY'),
                browse: record => history.push(`/deliveries/${record.id}`),
            },
            {
                title: 'Status',
                dataIndex: ['status'],
            },
        ]}
        // expandable={{
        //     expandedRowClassName: (record, index, indent) => 'test',
        //     indentSize: 0,
        //     expandedRowRender: (record, index, indent, expanded) => <TransferItems parent={record}/>
        //}}
    />
}

export default Received