import { employeeVisitApis, routeLocationApis } from '../api/api';
import RouteLocationFormFunction from './RouteLocationFormFunction';
import DataTable from '../core/DataTable/DataTable';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRoutes } from '../app/markerReducer';
import { AimOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ItemDrawer from '../core/ItemDrawer';
import moment from 'moment';
import VisitFormFunction from './VisitFormFunction';

const RouteLocations = ({ event }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [SelectedLocation, setSelectedLocation] = useState(undefined);
  const [ShowVistForm, setShowVisitForm] = useState(false);

  const addToMap = (data) => {
    let routes = data.content.map((routeLocation) => routeLocation.location);

    dispatch(setRoutes(routes));
  };

  return (
    <>
      <ItemDrawer
        itemName={'visit'}
        old={false}
        visible={ShowVistForm}
        onClose={(updated, newItem) => {
          setSelectedLocation(undefined);
          setShowVisitForm(false);
          updated && history.push(`/visits/${newItem.visit.id}`);
        }}
        initialValues={{
          dateTime: moment(),
          visit: {
            routeLocation: {
              id: SelectedLocation && SelectedLocation.id,
              location: {
                id:
                  SelectedLocation && SelectedLocation.organization.location.id,
                name: SelectedLocation && SelectedLocation.organization.name,
              },
            },
          },
        }}
        formToRestMapper={(data) => ({
          visit: {
            ...data.visit,
            routeLocation: {
              ...data.visit.routeLocation,
              location: undefined,
            },
            dateTime: data.dateTime.toISOString(),
          },
        })}
        apis={employeeVisitApis}
      >
        {VisitFormFunction(false, SelectedLocation)}
      </ItemDrawer>
      <DataTable
        browse={(record) =>
          history.push(`/organization/${record.organization.id}`)
        }
        dataProcessor={addToMap}
        parent={event}
        drag={true}
        apis={routeLocationApis}
        defaultFilters={{
          route: {
            id: event.routeId,
          },
        }}
        itemName={'route_location'}
        columns={[
          {
            title: 'Location',
            dataIndex: ['organization', 'name'],
            browse: (record) =>
              history.push(`/organization/${record.organization.id}`),
          },
          {
            align: 'center',
            width: 50,
            title: 'Visit',
            render: (text, record, index) => (
              <Button
                onClick={() => {
                  if (record.visit) {
                    history.push(`/visits/${record.visit.id}`);
                  }
                  setSelectedLocation(record);
                  setShowVisitForm(true);
                }}
                icon={<AimOutlined />}
              />
            ),
          },
        ]}
        drawerForm={{
          updatable: false,
          render: RouteLocationFormFunction,
        }}
        formToRestMapper={(data) => ({
          route: {
            id: data.route.id,
          },
          location: {
            id: data.location.id,
          },
          orderIndex: data.orderIndex,
        })}
      />
    </>
  );
};

export default RouteLocations;
