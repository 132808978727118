import {CheckOutlined, CloseOutlined, DeleteOutlined, SearchOutlined} from "@ant-design/icons"
import {Button, Drawer, Form, notification, Space, Spin} from "antd"
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from "react-router-dom"
import ErrorHandler from "../util/ErrorHandler"

const ItemDrawer = ({
                        item,
                        browse,
                        old,
                        apis,
                        children,
                        itemName,
                        onClose,
                        visible,
                        initialValues,
                        restToFormMapper,
                        formToRestMapper,
                        drawerFormUpdatable
                    }) => {
    const history = useHistory()

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [Item, setItem] = useState()

    const windowState = useSelector((state) => state.windowState)

    const [Updated, setUpdated] = useState(false)

    useEffect(() => {
        if (!old || !item || !visible) {
            setUpdated(false)
            return
        }
        setItem(item)
        form.setFieldsValue(restToFormMapper ? restToFormMapper(item) : item)
    }, [visible, item, form, old, restToFormMapper])

    useEffect(() => {
        if (!old)
            form.setFieldsValue(initialValues)
    }, [initialValues, form, old])

    const postItem = (item) => {
        setLoading(true)
        apis.postApi(formToRestMapper ? formToRestMapper({
            ...initialValues,
            ...item,
        }) : {
            ...initialValues,
            ...item,
        }).then(response => {
            if(!response) {
                setLoading(false)
                ErrorHandler(undefined, `Failed to create ${itemName} !`)
                return
            }

            notification['success']({
                message: `New ${itemName}`,
                description: `Successfully created ${itemName} !`,
            })
            setUpdated(true)
            setLoading(false)
            form.resetFields()
            onClose(true, response.data)
        }).catch(error => {
            ErrorHandler(error, `Failed to create ${itemName} !`, history)
            setLoading(false)
        })
    }


    const updateItem = (data) => {
        setLoading(true)

        apis.putApi(Item.id, formToRestMapper ? formToRestMapper({
            ...item,
            ...data,
        }, item) : {
            ...item,
            ...data,
        })
            .then(response => {
                if(!response) {
                    setLoading(false)
                    ErrorHandler(undefined, `Failed to update ${itemName} !`)
                    return
                }
                form.setFieldsValue(response.data)

                notification['success']({
                    message: `Updated ${itemName}`,
                    description: `Successfully updated ${itemName} !`,
                })
                setUpdated(true)
                setLoading(false)
            })
            .catch(error => {
                ErrorHandler(error, `Failed to update ${itemName} !`)
                setLoading(false)
            })
    }

    const deleteItem = () => {
        setLoading(true)

        apis.deleteApi(item.id, form.getFieldsValue())
            .then(response => {
                form.setFieldsValue(response.data)

                notification['success']({
                    message: `Delete ${itemName}`,
                    description: `Successfully deleted ${itemName} !`,
                })
                setLoading(false)
                form.resetFields()
                onClose(true)
            })
            .catch(error => {
                ErrorHandler(error, `Failed to delete ${itemName} !`)
                setLoading(false)
            })
    }

    const onFinish = (item) => {
        if (old) {
            updateItem(item)
        } else {
            postItem(item)
        }
    }

    const closeDrawer = () => {
        if (!loading) {
            form.resetFields()
            onClose(Updated)
        }
    }

    return <Drawer
        footer={<Space>
            {
                old && browse && <Button
                    loading={loading}
                    icon={<SearchOutlined/>}
                    type={'primary'}
                    onClick={() => {
                        browse(Item)
                        closeDrawer()
                    }}
                >
                    {'BROWSE'}
                </Button>
            }
            {
                old ? drawerFormUpdatable && apis.putApi && <Button
                    loading={loading}
                    icon={<CheckOutlined/>}
                    onClick={() => form.submit()}
                >
                    {'UPDATE'}
                </Button> : apis.postApi && <Button
                    loading={loading}
                    icon={<CheckOutlined/>}
                    type={'primary'}
                    onClick={() => form.submit()}
                >
                    {'CREATE'}
                </Button>
            }
            {
                old && apis.deleteApi && <Button
                    loading={loading}
                    icon={<DeleteOutlined/>}
                    onClick={() => deleteItem()}
                    danger={true}
                >
                    {'DELETE'}
                </Button>
            }
            <Button
                loading={loading}
                icon={<CloseOutlined/>}
                onClick={onClose}
            >
                {old ? 'CLOSE' : 'CANCEL'}
            </Button>
        </Space>}
        onClose={closeDrawer}
        title={old ? form.getFieldValue('name') ? form.getFieldValue('name') : itemName && itemName.charAt(0).toUpperCase() + itemName.slice(1) : `Create new ${itemName}`}
        visible={visible}
        width={windowState.mainMenuBroken ? '100%' : 500}
    >
        <Spin spinning={loading}>
            <Form
                form={form}
                onFinish={onFinish}
                layout={'vertical'}
                initialValues={initialValues}
            >
                {children}
            </Form>
        </Spin>
    </Drawer>
}

export default ItemDrawer