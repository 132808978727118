import DataTable from "../core/DataTable/DataTable";
import {deviceApis} from "../api/api";
import {useEffect, useState} from "react";

const Devices = ({parent, visible}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {

    }, [parent])

    return <DataTable
        itemName={'device'}
        parent={parent}
        editable={true}
        deletable={record => true}
        defaultFilters={{
            ownerId: parent.id,
        }}
        apis={deviceApis}
        columns={[
            {
                title: 'Imei',
                editable: record => true,
                dataIndex: ['imei'],
                type: 'text'
            }
        ]}
        formToRestMapper={(data, record) => ({
            imei: data.imei,
            ownerId: parent.id,
        })}
    />
}

export default Devices