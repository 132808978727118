import {Col, DatePicker, Form, Input, Row, Spin} from "antd"

const VisitForm = ({old, parent}) => {
    return <Spin spinning={false}>
        <Row>
            <Col
                xs={24}
            >
                <Form.Item
                    hidden={true}
                    label={'Location ID'}
                    name={['visit', 'routeLocation', 'id']}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label={'Location'}
                    name={['visit', 'routeLocation', 'location', 'name']}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label={'Time'}
                    name={['dateTime']}
                >
                    <DatePicker style={{width: '100%'}} showTime={true} disabled={true}/>
                </Form.Item>
            </Col>
        </Row>
    </Spin>
}

const VisitFormFunction = (old, parent) => <VisitForm old={old} parent={parent}/>

export default VisitFormFunction