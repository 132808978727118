import MainPage from '../core/MainPage';
import DataLineChart from '../core/Analytics/DataLineChart';
import { useParams } from 'react-router-dom';
import { employeementApis, employeeTargetApis, productApis } from '../api/api';
import moment from 'moment';
import ChartCard from '../core/Analytics/ChartCard';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  notification,
  Select,
  Table,
} from 'antd';
import { EditTwoTone, ExportOutlined } from '@ant-design/icons';
import DataAreaChart from '../core/Analytics/DataAreaChart';
import { setShowMap } from '../app/windowStateReducer';
import { useDispatch } from 'react-redux';
import PageDescriptions from '../core/PageDescriptions';
import TabSet from '../core/TabSet';
import NumberFormat from 'react-number-format';
import fileDownload from 'js-file-download';

const EmployeeTarget = () => {
  const dispatch = useDispatch();

  const { employeeId, productId } = useParams();
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
  const [endDate, setEndDate] = useState(moment().add(1, 'months'));
  const [type, setType] = useState('week');
  const [employee, setemployee] = useState();
  const [product, setProduct] = useState();
  const [targets, setTargets] = useState();

  useEffect(() => {
    dispatch(setShowMap(false));
  }, []);

  useEffect(() => {
    if (employeeId)
      employeementApis.getApi(employeeId).then((response) => {
        setemployee(response.data);
      });
  }, [employeeId]);

  useEffect(() => {
    if (productId)
      productApis.getApi(productId).then((response) => {
        setProduct(response.data);
      });
  }, [productId]);

  useEffect(() => {
    fetchTargets();
  }, [employeeId, productId, startDate, endDate, type]);

  const generateTargetRequest = () => ({
    employeeId,
    productId,
    startDate:
      type === 'month'
        ? startDate.date(1).hour(0).minute(0).second(0).millisecond(0)
        : startDate.hour(0).minute(0).second(0).millisecond(0),
    endDate:
      type === 'month'
        ? endDate.date(1).hour(0).minute(0).second(0).millisecond(0)
        : endDate.hour(0).minute(0).second(0).millisecond(0),
    type: type,
  });

  const fetchTargets = () => {
    employeeTargetApis.getBetween(generateTargetRequest()).then((response) => {
      setTargets(response.data);
    });
  };

  const [visualizationForm] = Form.useForm();
  const [updateForm] = Form.useForm();

  const [selectionType, setSelectionType] = useState('week');

  return (
    <MainPage
      headerProps={{
        title: 'Target',
      }}
      headerContent={
        <PageDescriptions
          items={[
            employee && {
              label: 'Employee',
              value: employee.user.firstName + ' ' + employee.user.lastName,
              url: `/employee/${employee.id}`,
            },
            product && {
              label: 'Product',
              value: product.name,
              url: `/product-category/${product.category.id}`,
            },
          ]}
        />
      }
    >
      <ChartCard
        onOk={() => {
          visualizationForm.submit();
          fetchTargets();
        }}
        title={'Delegated Targets VS Achievement'}
        height={400}
        settingsForm={
          <>
            <Divider>Visualize Targets</Divider>
            <Select
              onChange={(value) => setSelectionType(value)}
              value={selectionType}
              style={{ width: 'calc(100% - 20px)', margin: 20 }}
            >
              <Select.Option value={'date'}>Daily</Select.Option>
              <Select.Option value={'month'}>Monthly</Select.Option>
              <Select.Option value={'quarter'}>Quarterly</Select.Option>
              <Select.Option value={'year'}>Yearly</Select.Option>
            </Select>
            <Form
              colon={false}
              initialValues={{
                period: [startDate, endDate],
                startDate,
                endDate,
                type,
              }}
              form={visualizationForm}
              labelCol={{
                span: 5,
              }}
              onFinish={(data) => {
                setStartDate(data.period[0]);
                setEndDate(data.period[1]);
                setType(data.type);
              }}
            >
              <Form.Item name={'period'} label={'Period'}>
                <DatePicker.RangePicker
                  picker={selectionType}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item name={'type'} label={'Aggregation'}>
                <Select>
                  <Select.Option value={'day'}>Daily</Select.Option>
                  <Select.Option value={'week'}>Weekly</Select.Option>
                  <Select.Option value={'month'}>Monthly</Select.Option>
                  <Select.Option value={'year'}>Yearly</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <Divider>Update Targets</Divider>
            <Form
              colon={false}
              initialValues={{
                period: [startDate, endDate],
                startDate,
                endDate,
                type,
              }}
              form={updateForm}
              labelCol={{
                span: 5,
              }}
              onFinish={(data) => {
                employeeTargetApis
                  .updateTargets({
                    startDate: data.period[0]
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .millisecond(0),
                    endDate: data.period[1]
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .millisecond(0),
                    target: data.target,
                    employeeId,
                    productId,
                  })
                  .then((response) => {
                    notification['success']({
                      message: 'Successfully updated the targets !',
                    });
                  });
              }}
            >
              <Form.Item name={'period'} label={'Period'}>
                <DatePicker.RangePicker
                  picker={selectionType}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item name={'target'} label={'Target'}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 5,
                }}
              >
                <Button
                  icon={<EditTwoTone />}
                  onClick={() => updateForm.submit()}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </>
        }
      >
        {targets && targets.length > 0 && (
          <DataLineChart
            xAxisLabel={'Date'}
            yAxisLabel={'Amount'}
            data={[
              ...targets.map((target) => ({
                ...target,
                'Organization Delegation': target.organizationDelegation,
                'Employee Target': target.employeeTarget,
                Achievement: target.achieved,
                'Employee Delegation': target.employeeDelegation,
                name:
                  type === 'month'
                    ? moment(target.name).format('MMMM YYYY')
                    : type === 'year'
                    ? moment(target.name).format('YYYY')
                    : moment(target.name).format('MMMM DD'),
              })),
            ]}
            structure={{
              items: [
                {
                  dataIndex: 'Organization Delegation',
                  color: 'pink',
                },
                {
                  dataIndex: 'Employee Delegation',
                  color: 'blue',
                },
                {
                  dataIndex: 'Employee Target',
                  color: 'red',
                },
                {
                  dataIndex: 'Achievement',
                  color: 'green',
                },
              ],
            }}
          />
        )}
      </ChartCard>
      {targets && targets.length > 0 && (
        <TabSet
          routes={{
            default: 'organization_delegation',
            pathSection: 7,
            routes: [
              {
                permission: 'target:list',
                name: 'Employee',
                dataIndex: 'employee_delegation',
                component: (visible) => (
                  <>
                    <div>
                      <ChartCard
                        onOk={() => {
                          visualizationForm.submit();
                          fetchTargets();
                        }}
                        title={'Employee Delegation Composition'}
                        height={400}
                        settingsForm={
                          <>
                            <Divider>Visualize Targets</Divider>
                            <Select
                              onChange={(value) => setSelectionType(value)}
                              value={selectionType}
                              style={{ width: 'calc(100% - 20px)', margin: 20 }}
                            >
                              <Select.Option value={'date'}>
                                Daily
                              </Select.Option>
                              <Select.Option value={'month'}>
                                Monthly
                              </Select.Option>
                              <Select.Option value={'quarter'}>
                                Quarterly
                              </Select.Option>
                              <Select.Option value={'year'}>
                                Yearly
                              </Select.Option>
                            </Select>
                            <Form
                              colon={false}
                              initialValues={{
                                period: [startDate, endDate],
                                startDate,
                                endDate,
                                type,
                              }}
                              form={visualizationForm}
                              labelCol={{
                                span: 5,
                              }}
                              onFinish={(data) => {
                                setStartDate(data.period[0]);
                                setEndDate(data.period[1]);
                                setType(data.type);
                              }}
                            >
                              <Form.Item name={'period'} label={'Period'}>
                                <DatePicker.RangePicker
                                  picker={selectionType}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                              <Form.Item name={'type'} label={'Aggregation'}>
                                <Select>
                                  <Select.Option value={'day'}>
                                    Daily
                                  </Select.Option>
                                  <Select.Option value={'week'}>
                                    Weekly
                                  </Select.Option>
                                  <Select.Option value={'month'}>
                                    Monthly
                                  </Select.Option>
                                  <Select.Option value={'year'}>
                                    Yearly
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Form>
                            <Divider>Update Targets</Divider>
                            <Form
                              colon={false}
                              initialValues={{
                                period: [startDate, endDate],
                                startDate,
                                endDate,
                                type,
                              }}
                              form={updateForm}
                              labelCol={{
                                span: 5,
                              }}
                              onFinish={(data) => {
                                employeeTargetApis
                                  .updateTargets({
                                    startDate: data.period[0]
                                      .hour(0)
                                      .minute(0)
                                      .second(0)
                                      .millisecond(0),
                                    endDate: data.period[1]
                                      .hour(0)
                                      .minute(0)
                                      .second(0)
                                      .millisecond(0),
                                    target: data.target,
                                    employeeId,
                                    productId,
                                  })
                                  .then((response) => {
                                    notification['success']({
                                      message:
                                        'Successfully updated the targets !',
                                    });
                                  });
                              }}
                            >
                              <Form.Item name={'period'} label={'Period'}>
                                <DatePicker.RangePicker
                                  picker={selectionType}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                              <Form.Item name={'target'} label={'Target'}>
                                <InputNumber style={{ width: '100%' }} />
                              </Form.Item>
                              <Form.Item
                                wrapperCol={{
                                  offset: 5,
                                }}
                              >
                                <Button
                                  icon={<EditTwoTone />}
                                  onClick={() => updateForm.submit()}
                                >
                                  Update
                                </Button>
                              </Form.Item>
                            </Form>
                          </>
                        }
                      >
                        {targets && (
                          <DataAreaChart
                            xAxisDataIndex={'name'}
                            xAxisLabel={'Date'}
                            yAxisLabel={'Amount'}
                            data={[
                              ...targets.map((target) => ({
                                ...target,
                                ...target.employeeTargets,
                                name:
                                  type === 'month'
                                    ? moment(target.name).format('MMMM YYYY')
                                    : type === 'year'
                                    ? moment(target.name).format('YYYY')
                                    : moment(target.name).format('MMMM DD'),
                              })),
                            ]}
                            stacked={true}
                            structure={{
                              items: [
                                ...Object.entries(
                                  targets[0].employeeTargets
                                ).map(([k, v]) => ({
                                  dataIndex: k,
                                })),
                              ],
                            }}
                          />
                        )}
                      </ChartCard>
                    </div>
                  </>
                ),
              },
              {
                permission: 'target:list',
                name: 'Organization',
                dataIndex: 'organization_delegation',
                component: (visible) => (
                  <>
                    <div>
                      <ChartCard
                        onOk={() => {
                          visualizationForm.submit();
                          fetchTargets();
                        }}
                        title={'Organization Achievement Composition'}
                        height={400}
                        settingsForm={
                          <>
                            <Divider>Visualize Targets</Divider>
                            <Select
                              onChange={(value) => setSelectionType(value)}
                              value={selectionType}
                              style={{ width: 'calc(100% - 20px)', margin: 20 }}
                            >
                              <Select.Option value={'date'}>
                                Daily
                              </Select.Option>
                              <Select.Option value={'month'}>
                                Monthly
                              </Select.Option>
                              <Select.Option value={'quarter'}>
                                Quarterly
                              </Select.Option>
                              <Select.Option value={'year'}>
                                Yearly
                              </Select.Option>
                            </Select>
                            <Form
                              colon={false}
                              initialValues={{
                                period: [startDate, endDate],
                                startDate,
                                endDate,
                                type,
                              }}
                              form={visualizationForm}
                              labelCol={{
                                span: 5,
                              }}
                              onFinish={(data) => {
                                setStartDate(data.period[0]);
                                setEndDate(data.period[1]);
                                setType(data.type);
                              }}
                            >
                              <Form.Item name={'period'} label={'Period'}>
                                <DatePicker.RangePicker
                                  picker={selectionType}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                              <Form.Item name={'type'} label={'Aggregation'}>
                                <Select>
                                  <Select.Option value={'day'}>
                                    Daily
                                  </Select.Option>
                                  <Select.Option value={'week'}>
                                    Weekly
                                  </Select.Option>
                                  <Select.Option value={'month'}>
                                    Monthly
                                  </Select.Option>
                                  <Select.Option value={'year'}>
                                    Yearly
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Form>
                            <Divider>Update Targets</Divider>
                            <Form
                              colon={false}
                              initialValues={{
                                period: [startDate, endDate],
                                startDate,
                                endDate,
                                type,
                              }}
                              form={updateForm}
                              labelCol={{
                                span: 5,
                              }}
                              onFinish={(data) => {
                                employeeTargetApis
                                  .updateTargets({
                                    startDate: data.period[0]
                                      .hour(0)
                                      .minute(0)
                                      .second(0)
                                      .millisecond(0),
                                    endDate: data.period[1]
                                      .hour(0)
                                      .minute(0)
                                      .second(0)
                                      .millisecond(0),
                                    target: data.target,
                                    employeeId,
                                    productId,
                                  })
                                  .then((response) => {
                                    notification['success']({
                                      message:
                                        'Successfully updated the targets !',
                                    });
                                  });
                              }}
                            >
                              <Form.Item name={'period'} label={'Period'}>
                                <DatePicker.RangePicker
                                  picker={selectionType}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                              <Form.Item name={'target'} label={'Target'}>
                                <InputNumber style={{ width: '100%' }} />
                              </Form.Item>
                              <Form.Item
                                wrapperCol={{
                                  offset: 5,
                                }}
                              >
                                <Button
                                  icon={<EditTwoTone />}
                                  onClick={() => updateForm.submit()}
                                >
                                  Update
                                </Button>
                              </Form.Item>
                            </Form>
                          </>
                        }
                      >
                        {targets && (
                          <DataAreaChart
                            xAxisDataIndex={'name'}
                            xAxisLabel={'Date'}
                            yAxisLabel={'Amount'}
                            data={[
                              ...targets.map((target) => ({
                                ...target,
                                ...target.organizationAchievements,
                                name:
                                  type === 'month'
                                    ? moment(target.name).format('MMMM YYYY')
                                    : type === 'year'
                                    ? moment(target.name).format('YYYY')
                                    : moment(target.name).format('MMMM DD'),
                              })),
                            ]}
                            stacked={true}
                            structure={{
                              items: [
                                ...Object.entries(
                                  targets[0].organizationAchievements
                                ).map(([k, v]) => ({
                                  dataIndex: k,
                                })),
                              ],
                            }}
                          />
                        )}
                      </ChartCard>
                    </div>
                    <div>
                      <ChartCard
                        onOk={() => {
                          visualizationForm.submit();
                          fetchTargets();
                        }}
                        title={'Organization Delegation Composition'}
                        height={400}
                        settingsForm={
                          <>
                            <Divider>Visualize Targets</Divider>
                            <Select
                              onChange={(value) => setSelectionType(value)}
                              value={selectionType}
                              style={{ width: 'calc(100% - 20px)', margin: 20 }}
                            >
                              <Select.Option value={'date'}>
                                Daily
                              </Select.Option>
                              <Select.Option value={'month'}>
                                Monthly
                              </Select.Option>
                              <Select.Option value={'quarter'}>
                                Quarterly
                              </Select.Option>
                              <Select.Option value={'year'}>
                                Yearly
                              </Select.Option>
                            </Select>
                            <Form
                              colon={false}
                              initialValues={{
                                period: [startDate, endDate],
                                startDate,
                                endDate,
                                type,
                              }}
                              form={visualizationForm}
                              labelCol={{
                                span: 5,
                              }}
                              onFinish={(data) => {
                                setStartDate(data.period[0]);
                                setEndDate(data.period[1]);
                                setType(data.type);
                              }}
                            >
                              <Form.Item name={'period'} label={'Period'}>
                                <DatePicker.RangePicker
                                  picker={selectionType}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                              <Form.Item name={'type'} label={'Aggregation'}>
                                <Select>
                                  <Select.Option value={'day'}>
                                    Daily
                                  </Select.Option>
                                  <Select.Option value={'week'}>
                                    Weekly
                                  </Select.Option>
                                  <Select.Option value={'month'}>
                                    Monthly
                                  </Select.Option>
                                  <Select.Option value={'year'}>
                                    Yearly
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Form>
                            <Divider>Update Targets</Divider>
                            <Form
                              colon={false}
                              initialValues={{
                                period: [startDate, endDate],
                                startDate,
                                endDate,
                                type,
                              }}
                              form={updateForm}
                              labelCol={{
                                span: 5,
                              }}
                              onFinish={(data) => {
                                employeeTargetApis
                                  .updateTargets({
                                    startDate: data.period[0]
                                      .hour(0)
                                      .minute(0)
                                      .second(0)
                                      .millisecond(0),
                                    endDate: data.period[1]
                                      .hour(0)
                                      .minute(0)
                                      .second(0)
                                      .millisecond(0),
                                    target: data.target,
                                    employeeId,
                                    productId,
                                  })
                                  .then((response) => {
                                    notification['success']({
                                      message:
                                        'Successfully updated the targets !',
                                    });
                                  });
                              }}
                            >
                              <Form.Item name={'period'} label={'Period'}>
                                <DatePicker.RangePicker
                                  picker={selectionType}
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                              <Form.Item name={'target'} label={'Target'}>
                                <InputNumber style={{ width: '100%' }} />
                              </Form.Item>
                              <Form.Item
                                wrapperCol={{
                                  offset: 5,
                                }}
                              >
                                <Button
                                  icon={<EditTwoTone />}
                                  onClick={() => updateForm.submit()}
                                >
                                  Update
                                </Button>
                              </Form.Item>
                            </Form>
                          </>
                        }
                      >
                        {targets && (
                          <DataAreaChart
                            xAxisDataIndex={'name'}
                            xAxisLabel={'Date'}
                            yAxisLabel={'Amount'}
                            data={[
                              ...targets.map((target) => ({
                                ...target,
                                ...target.organizationTargets,
                                name:
                                  type === 'month'
                                    ? moment(target.name).format('MMMM YYYY')
                                    : type === 'year'
                                    ? moment(target.name).format('YYYY')
                                    : moment(target.name).format('MMMM DD'),
                              })),
                            ]}
                            stacked={true}
                            structure={{
                              items: [
                                ...Object.entries(
                                  targets[0].organizationTargets
                                ).map(([k, v]) => ({
                                  dataIndex: k,
                                })),
                              ],
                            }}
                          />
                        )}
                      </ChartCard>
                    </div>
                  </>
                ),
              },
            ],
          }}
        />
      )}
      <Card
        extra={[
          <Button
            icon={<ExportOutlined />}
            onClick={() => {
              employeeTargetApis
                .exportTargets(generateTargetRequest())
                .then((response) => {
                  fileDownload(response.data, 'export-employee-targets.csv');
                });
            }}
          />,
        ]}
      >
        <Table
          pagination={false}
          columns={[
            {
              title: 'Date',
              dataIndex: ['name'],
              render: (text) => <span>{moment(text).format('ll')}</span>,
            },
            {
              title: 'Employee Target',
              dataIndex: ['employeeTarget'],
              align: 'right',
              render: (text) => (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={text}
                />
              ),
            },
            {
              title: 'Employee Delegation',
              dataIndex: ['employeeDelegation'],
              align: 'right',
              render: (text) => (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={text}
                />
              ),
            },
            {
              title: 'Organization Delegation',
              dataIndex: ['organizationDelegation'],
              align: 'right',
              render: (text) => (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={text}
                />
              ),
            },
            {
              title: 'Achieved',
              dataIndex: ['achieved'],
              align: 'right',
              render: (text) => (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={text}
                />
              ),
            },
            {
              title: 'Percentage',
              dataIndex: ['percentage'],
              align: 'right',
              render: (text) => (
                <NumberFormat
                  displayType={'text'}
                  thousandSeparator={true}
                  value={text}
                />
              ),
            },
          ]}
          dataSource={targets}
        />
      </Card>
    </MainPage>
  );
};

export default EmployeeTarget;
