import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import markerReducer from './markerReducer'
import windowStateReducer from './windowStateReducer'
// import userManager from "./userManager";
import profile from "./profile";

const store = configureStore({
    reducer: {
        profile: profile,
        // oidc: oidcReducer,
        windowState: windowStateReducer,
        marker: markerReducer,
    },
})

// loadUser(store, userManager)

export default store