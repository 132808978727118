import {
  Button,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import {
  ExportOutlined,
  FileAddOutlined,
  ImportOutlined,
  MenuOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import ItemDrawer from "../ItemDrawer";
import { sortableContainer, sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import ErrorHandler from "../../util/ErrorHandler";
import { useHistory } from "react-router-dom";
import { JsonToTable } from "react-json-to-table";
import { isArray } from "@craco/craco/lib/utils";
import DraggableBodyRow from "./DraggableBodyRow";
import EditableCell from "./EditableCell";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";
import RoleCheck from "../../util/RoleCheck";
import moment from "moment";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "black" }} />
));
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const flattenObject = (ob) => {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

const DataTable = (props) => {
  const {
    apis,
    onChange,
    value,
    select,
    expandable,
    deletable,
    editable,
    browse,
    drag,
    dataProcessor,
    parent,
    defaultFilters,
    columns,
    itemName,
    drawerForm,
    restToTableMapper,
    restToFormMapper,
    formToRestMapper,
    initialValues,
    disableActions = false,
  } = props;

  const history = useHistory();

  const [Page, setPage] = useState(0);
  const [PageSize, setPageSize] = useState(10);

  const [SortBy, setSortBy] = useState("lastModifiedDate");
  const [SortDirection, setSortDirection] = useState("DESC");

  const searchInput = useRef();

  const [ListLoading, setListLoading] = useState(true);
  const [DataItems, setDataItems] = useState({
    content: [],
  });
  const DataItemsRef = useRef(DataItems);

  const [Old, setOld] = useState(false);
  const [ShowItemDrawer, setShowItemDrawer] = useState(false);
  const [SelectedItem, setSelectedItem] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const [historyId, setHistoryId] = useState();

  const [showFullData, setShowFullData] = useState(false);
  const [showFullDataId, setShowFullDataId] = useState();

  const [filters, setFilters] = useState({});

  const [postEnabled, setPostEnabled] = useState(false);
  const [putEnabled, setPutEnabled] = useState(false);
  const [deleteEnabled, setDeleteEnabled] = useState(false);

  const userState = useSelector((state) => state.profile.user);

  useEffect(() => {
    if (RoleCheck(userState, itemName + ":post")) {
      setPostEnabled(true);
    }
    if (RoleCheck(userState, itemName + ":put")) {
      setPutEnabled(true);
    }
    if (RoleCheck(userState, itemName + ":delete")) {
      setDeleteEnabled(true);
    }
  }, []);

  useEffect(() => {
    DataItemsRef.current = DataItems;
  }, [DataItems]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      apis
        .putApi(
          DataItems.content[oldIndex].id,
          formToRestMapper
            ? formToRestMapper(
                {
                  ...DataItems.content[oldIndex],
                  orderIndex: newIndex,
                },
                DataItems.content[oldIndex]
              )
            : {
                ...DataItems.content[oldIndex],
                orderIndex: newIndex,
              }
        )
        .then((response) => {
          listItems();
          notification["success"]({
            message: "Successfully update the order !",
          });
        })
        .catch((error) => {
          ErrorHandler(error, "Failed to update the order !");
        });

      const newData = arrayMove(
        [].concat(DataItems.content),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      newData.map((item, index) => ({
        ...item,
        order: index,
      }));
      setDataItems({
        ...DataItems,
        content: newData,
      });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const listItems = () => {
    setListLoading(true);
    apis &&
      apis.listApi &&
      apis
        .listApi(
          {
            pageable: {
              page: Page,
              size: drag ? 1000 : PageSize,
              sort: drag ? "orderIndex" : SortBy,
              direction: drag ? "ASC" : SortDirection,
            },
            entity: {
              ...defaultFilters,
              ...filters,
            },
          },
          parent && parent.id
        )
        .then((response) => {
          if (!response) {
            return;
          }
          dataProcessor && dataProcessor(response.data);
          setDataItems(response.data);
          setSelectedItem(undefined);
          setListLoading(false);
        })
        .catch((error) => {
          ErrorHandler(error, `Failed to list ${itemName}`, history);
        });
  };

  useEffect(() => {
    listItems();
  }, [parent, Page, PageSize, SortBy, SortDirection, filters]);

  useEffect(
    () =>
      document.addEventListener(
        "keydown",
        (event) => event.ctrlKey && event.key === "n" && createNewItem()
      ),
    []
  );

  const buildFilterObject = (column, existingValue, newValue) => {
    let obj = {};

    if (existingValue) {
      obj = {
        ...existingValue,
      };
    }

    if (column.type === "select") {
      if (column.persistedDataIndex.length === 1) {
        obj[column.persistedDataIndex] = newValue;
        return obj;
      } else {
        obj[column.persistedDataIndex[0]] = buildFilterObject(
          {
            ...column,
            persistedDataIndex: column.persistedDataIndex.slice(
              1,
              column.persistedDataIndex.length
            ),
          },
          existingValue
            ? existingValue[column.persistedDataIndex[0]]
            : undefined,
          newValue
        );
        return obj;
      }
    }
    if (column.dataIndex.length === 1) {
      obj[column.dataIndex] = newValue;
      return obj;
    }

    obj[column.dataIndex[0]] = buildFilterObject(
      {
        ...column,
        dataIndex: column.dataIndex.slice(1, column.dataIndex.length),
      },
      existingValue ? existingValue[column.dataIndex[0]] : undefined,
      newValue
    );
    return obj;
  };

  const handleSearch = (selectedKeys, confirm, column) => {
    if (
      (column.type === "select" && isArray(column.persistedDataIndex)) ||
      (column.type !== "select" && isArray(column.dataIndex))
    ) {
      setFilters(buildFilterObject(column, filters, selectedKeys[0]));
    } else {
      let obj = {
        ...filters,
      };
      if (column.type === "select") {
        obj[column.persistedDataIndex] = selectedKeys[0];
      } else {
        obj[column.dataIndex] = selectedKeys[0];
      }
    }

    confirm();
  };

  const handleReset = (clearFilters) => {
    setFilters(defaultFilters);
    clearFilters();
  };

  const getColumnSearchProps = (column) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 10 }}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, column)}
          style={{ marginBottom: 10, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, column)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const doExport = async () => {
    apis
      .exportApi(
        {
          pageable: {
            page: Page,
            size: PageSize,
            sort: drag ? "orderIndex" : SortBy,
            direction: drag ? "ASC" : SortDirection,
          },
          entity: {
            ...defaultFilters,
            ...filters,
          },
        },
        parent && parent.id
      )
      .then((response) => {
        fileDownload(response.data, "export.csv");
      });
  };

  const generateColumns = () => {
    // Data columns
    var tmp = columns
      ? columns.map((column) =>
          column.dataIndex === undefined
            ? column
            : {
                ...column,
                ...getColumnSearchProps(column),
                sortDirections: ["ascend", "descend"],
                sorter: (a, b) => {
                  return a[column.dataIndex] < b[column.dataIndex] ? -1 : 1;
                },
                onCell: (record) => ({ record, column }),
                render: (text, record, index) =>
                  column.browse ? (
                    <Button
                      size={"small"}
                      type={"link"}
                      onClick={() => column.browse(record)}
                    >
                      {column.render
                        ? column.render(text, record, index)
                        : text}
                    </Button>
                  ) : (
                    text || "Not Available"
                  ),
              }
        )
      : [];

    // Drag handle column
    if (drag) {
      tmp.unshift({
        title: "Sort",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        align: "center",
        render: () => <DragHandle />,
      });
    }

    !disableActions &&
      tmp.push({
        title: "Actions",
        render: (text, record, index) => {
          return <div></div>;
        },
      });

    return tmp;
  };

  const createNewItem = () => {
    if (editable && !drawerForm) {
      // if(DataItemsRef.current.content.filter(item => item.temp === true).length > 0) return

      setDataItems({
        ...DataItemsRef.current,
        content: !drag
          ? [
              {
                ...defaultFilters,
                ...initialValues,
                temp: true,
                id: "new",
              },
              ...DataItemsRef.current.content,
            ]
          : [
              ...DataItemsRef.current.content,
              {
                ...defaultFilters,
                ...initialValues,
                temp: true,
                id: "new",
              },
            ],
      });
    } else {
      setShowItemDrawer(true);
    }
  };

  return (
    <Spin spinning={ListLoading}>
      {drawerForm && (
        <ItemDrawer
          item={SelectedItem}
          parent={parent}
          browse={browse}
          old={Old}
          apis={apis}
          itemName={itemName}
          onClose={(updated) => {
            updated && listItems();
            setOld(false);
            setSelectedItem(undefined);
            setShowItemDrawer(false);
            drawerForm && drawerForm.update && drawerForm.update();
          }}
          visible={ShowItemDrawer}
          initialValues={{
            ...defaultFilters,
            ...initialValues,
          }}
          restToFormMapper={restToFormMapper}
          formToRestMapper={formToRestMapper}
          drawerFormUpdatable={drawerForm.updatable}
        >
          {drawerForm.render(Old, parent)}
        </ItemDrawer>
      )}

      <Modal
        width={"100%"}
        visible={showHistory}
        onCancel={() => setShowHistory(false)}
        onOk={() => setShowHistory(false)}
        title={"History"}
        editable={false}
        // forceRender={true}
      >
        <DataTable
          {...props}
          parent={historyId}
          columns={[
            {
              title: "Date",
              dataIndex: ["revisionInstant"],
            },
            ...props.columns.map((column) => ({
              ...column,
              editable: (record) => false,
            })),
          ]}
          apis={{
            ...apis,
            listApi: () =>
              apis.listRevisions({
                id: historyId,
              }),
          }}
          restToTableMapper={(data) => ({
            ...data.metadata,
            ...data.entity,
            revisionInstant: moment(
              data.metadata.revisionInstant
            ).toLocaleString(),
          })}
        />
      </Modal>

      <Modal
        title={"Full Data View"}
        width={"95%"}
        visible={showFullData}
        onOk={() => setShowFullData(false)}
        onCancel={() => setShowFullData(false)}
        bodyStyle={{
          overflow: "auto",
        }}
      >
        <JsonToTable json={showFullDataId} />
      </Modal>

      <Row
        style={{
          padding: 10,
        }}
      >
        <Space>
          {(editable || drawerForm) && postEnabled && (
            <Button icon={<FileAddOutlined />} onClick={createNewItem}>
              {"NEW"}
            </Button>
          )}
          {drawerForm && <Button icon={<ImportOutlined />}>{"IMPORT"}</Button>}
          <Button icon={<ExportOutlined />} onClick={doExport}>
            {"EXPORT"}
          </Button>
          <Button icon={<PrinterOutlined />}>{"PRINT"}</Button>
        </Space>
      </Row>
      <Table
        expandable={expandable}
        size={"small"}
        bordered={true}
        rowKey="id"
        onChange={(pagination, filters, sorter, extra) => {
          if (sorter.order) {
            if (isArray(sorter.field)) {
              setSortBy(sorter.field.join("."));
            } else {
              setSortBy(sorter.field);
            }
            setSortDirection(sorter.order === "ascend" ? "ASC" : "DESC");
          } else {
            setSortBy(drag ? "orderIndex" : "lastModifiedDate");
            setSortDirection("DESC");
          }
        }}
        onRow={(record, index) => ({
          showDrawer: browse
            ? () => {
                setSelectedItem(record);
                setOld(true);
                setShowItemDrawer(true);
              }
            : undefined,
          editable: putEnabled && editable,
          deletable: deleteEnabled && deletable,
          record,
          index,
          disableActions: disableActions,
          putApi: (id, data) =>
            apis.putApi(record.id, {
              ...defaultFilters,
              ...record,
              ...data,
            }),
          postApi: (data) =>
            new Promise((resolve, reject) =>
              apis
                .postApi({
                  ...defaultFilters,
                  ...data,
                  orderIndex: drag ? DataItems.content.length - 1 : 0,
                })
                .then((response) => {
                  setDataItems({
                    ...DataItems,
                    content: !drag
                      ? [
                          response.data,
                          ...DataItems.content.slice(
                            1,
                            DataItems.content.length === PageSize
                              ? DataItems.content.length - 1
                              : DataItems.content.length
                          ),
                        ]
                      : [
                          ...DataItems.content.slice(
                            0,
                            DataItems.content.length === PageSize
                              ? DataItems.content.length - 2
                              : DataItems.content.length - 1
                          ),
                          response.data,
                        ],
                  });
                  resolve();
                })
                .catch((error) => {
                  ErrorHandler(error, "Failed to create the line !");
                  reject();
                })
            ),
          formToRestMapper,
          restToFormMapper,
          deleteApi: () => {
            return new Promise((resolve, reject) => {
              if (record.temp) {
                setDataItems({
                  ...DataItems,
                  content: DataItems.content.filter(
                    (item) => item.id !== record.id
                  ),
                });
                resolve();
                return;
              }
              apis
                .deleteApi(record.id)
                .then((response) => {
                  setDataItems({
                    ...DataItems,
                    content: DataItems.content.filter(
                      (item) => item.id !== record.id
                    ),
                  });
                  resolve();
                })
                .catch((error) => {
                  ErrorHandler(error, "Failed to delete the line !");
                  reject();
                });
            });
          },
          browse,
          onHistory: (data) => {
            setHistoryId(data.id);
            setShowHistory(true);
          },
          fullData: (data) => {
            setShowFullDataId(data);
            setShowFullData(true);
          },
        })}
        columns={generateColumns()}
        dataSource={
          restToTableMapper
            ? DataItems.content.map(restToTableMapper)
            : DataItems.content
        }
        style={{
          margin: "0 10px",
          overflow: "auto",
        }}
        pagination={
          drag
            ? false
            : {
                defaultCurrent: 1,
                showSizeChanger: true,
                size: PageSize,
                current: Page + 1,
                total: DataItems.totalElements,
                onChange: (page, pageSize) => {
                  setPage(page - 1);
                  setPageSize(pageSize);
                },
              }
        }
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
            cell: EditableCell,
          },
        }}
        rowSelection={
          (onChange || value) && {
            type: (select && select.type) || "checkbox",
            onChange: (selectedRowKeys, selectedRows) => onChange(selectedRows),
          }
        }
      />
    </Spin>
  );
};

export default DataTable;
