import {Col, Form, Input, Row, Spin} from "antd"

const EmployeeLevelForm = (old) => {

    return <Spin spinning={false}>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={'Name'}
                    name={'name'}
                    rules={[
                        {
                            required: true,
                            message: 'Name is required !'
                        }
                    ]}
                >
                    <Input
                        placeholder={'Name'}
                    />
                </Form.Item>
            </Col>
        </Row>
        {
            old && <Row>
                <Col xs={24}>
                    <Form.Item
                        label={'Order Index'}
                        name={'orderIndex'}
                    >
                        <Input
                            placeholder={'Name'}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        }
    </Spin>
}

const EmployeeLevelFormFunction = (old) => <EmployeeLevelForm old={old}/>

export default EmployeeLevelFormFunction