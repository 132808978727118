import DataTable from "../core/DataTable/DataTable";
import {inventoryTargetApis} from "../api/api";
import {useHistory} from "react-router-dom";
import InventoryTargetFormFunction from "./TargetFormFunction";

const Targets = ({visible, parent}) => {
    const history = useHistory()

    return <DataTable
        itemName={'inventory_target'}
        browse={record => history.push(`/inventory-targets/inventory/${parent.inventory.id}/product/${record.id}`)}
        parent={parent && parent.inventory}
        editable={true}
        deletable={record => true}
        apis={{
            postApi: inventoryTargetApis.updateTargets,
            listApi: inventoryTargetApis.getDistinct,
        }}
        columns={[
            {
                title: 'Product',
                dataIndex: ['name'],
            },
        ]}
        formToRestMapper={data => ({
            ...data,
            inventory: {
                id: parent.inventory.id,
            },
            startDate: data.period[0].hour(0).minute(0).second(0).millisecond(0),
            endDate: data.period[1].hour(0).minute(0).second(0).millisecond(0),
        })}
        drawerForm={{
            render: InventoryTargetFormFunction,
            updatable: true,
        }}
    />
}

export default Targets