const RoleCheck = (userState, role) => {
  const roles =
    userState &&
    userState.resource_access.sdm &&
    userState.resource_access.sdm.roles;
  if (roles) {
    if (roles.includes(role) || roles.includes('super-user')) {
      return true;
    }
  }
  return false;
};

export default RoleCheck;
