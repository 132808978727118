import {Button, Descriptions} from "antd"
import {useHistory} from "react-router-dom"

const PageDescriptions = ({items}) => {
    const history = useHistory()

    return <Descriptions
        colon={false}
        size={'small'}
        column={{xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1}}
    >
        {
            items.map(item => <Descriptions.Item label={item && item.label}>
                {item && item.url ? <Button
                    type={'link'}
                    size={'small'}
                    onClick={() => history.push(item && item.url)}>
                    {item && (item.value || 'Not Available')}
                </Button> : item && item.render ? item.render(item.value) : item && (item.value || 'Not Available')}
            </Descriptions.Item>)
        }
    </Descriptions>
}

export default PageDescriptions