import { Responsive, WidthProvider } from 'react-grid-layout';
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setShowMap } from "../app/windowStateReducer";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import ChartCard from "./Analytics/ChartCard";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({ items, layouts, onLayoutChanged, editable }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setShowMap(false))
    }, [])

    return <div
        style={{
            position: 'relative',
            width: '100%',
            padding: 10,
        }}
    >
        <ResponsiveGridLayout
            onLayoutChange={onLayoutChanged}
            isDraggable={editable}
            isResizable={editable}
            className="layout"
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
            layouts={layouts}
        >
            {
                items.map((item, index) => <div key={`${index}-key`}>
                    {
                        item.customCard ? item.customCard : <ChartCard
                            title={item.title || 'Title'}
                            settingsForm={item.settings}
                        >
                            {item.children}
                        </ChartCard>
                    }
                </div>)
            }
        </ResponsiveGridLayout>
    </div>
}

export default Dashboard