import { Button, Spin } from 'antd';
import { employeementApis } from '../api/api';
import MainPage from '../core/MainPage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import EmployeeClients from './Clients';
import Supervisees from './Supervisees';
import { MoreOutlined } from '@ant-design/icons';
import Routes from './Routes';
import ProductCategories from './ProductCategories';
import ErrorHandler from '../util/ErrorHandler';
import TabSet from '../core/TabSet';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMap } from '../app/windowStateReducer';
import { TestDashboard } from '../core/Dashboard';
import EmployeeTargets from './EmployeeTargets';
import PageDescriptions from '../core/PageDescriptions';
import ItemDrawer from '../core/ItemDrawer';
import EmployeeFormFunction from './EmployeeFormFunction';
import Devices from './Devices';
import Analytics from './Analytics';
import EmployeeAnalytics from './Analytics';
import Visits from './Visits';

const Employee = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.profile.user);

  const [LoadingEmployee, setLoadingEmployee] = useState(false);
  const [Employee, setEmployee] = useState(undefined);

  const [ShowDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    dispatch(setShowMap(false));
  }, []);

  useEffect(() => {
    if (user) fetchEmployee();
  }, [id]);

  const fetchEmployee = () => {
    setLoadingEmployee(true);
    employeementApis
      .getApi(id)
      .then((response) => {
        setEmployee(response.data);
        setLoadingEmployee(false);
      })
      .catch((error) => {
        ErrorHandler(error, 'Failed to get employee !');
        setLoadingEmployee(false);
      });
  };

  return (
    <MainPage
      headerProps={{
        title: Employee
          ? `${Employee.user.firstName} ${Employee.user.lastName}`
          : 'Loading ...',
        subTitle: Employee && Employee.title.name,
        extra: [
          <Button icon={<MoreOutlined />} onClick={() => setShowDrawer(true)}>
            MORE
          </Button>,
        ],
        // footer: ,
      }}
      headerContent={
        Employee && (
          <PageDescriptions
            items={[
              {
                label: 'Employeed In',
                value: Employee.organization.name,
                url: `/organization/${Employee.organization.id}`,
              },
              {
                label: 'Email',
                value: Employee.user.email,
              },
              {
                label: 'Supervised By',
                value:
                  Employee.supervisor.length > 0 &&
                  Employee.supervisor[0].supervisor.user.firstName +
                    ' ' +
                    Employee.supervisor[0].supervisor.user.lastName,
                url:
                  Employee.supervisor.length > 0 &&
                  `/employee/${Employee.supervisor[0].supervisor.id}`,
              },
            ]}
          />
        )
      }
      item={Employee}
    >
      {Employee && (
        <ItemDrawer
          item={Employee}
          apis={employeementApis}
          old={true}
          itemName={'employee'}
          restToFormMapper={(employeement) => ({
            ...employeement,
            name: `${employeement.user.firstName} ${employeement.user.lastName}`,
          })}
          onClose={(updated) => {
            updated && fetchEmployee();
            setShowDrawer(false);
          }}
          visible={ShowDrawer}
          drawerFormUpdatable={true}
        >
          {EmployeeFormFunction(true)}
        </ItemDrawer>
      )}
      <Spin
        spinning={LoadingEmployee}
        style={{
          minHeight: 400,
          minWidth: '100%',
        }}
      >
        {Employee && (
          <TabSet
            routes={{
              default: 'clients',
              routes: [
                {
                  permission: 'employee_client:list',
                  name: 'Clients',
                  dataIndex: 'clients',
                  component: (visible) => (
                    <EmployeeClients visible={visible} parent={Employee} />
                  ),
                },
                {
                  permission: 'supervision:list',
                  name: 'Supervisees',
                  dataIndex: 'supervisees',
                  component: (visible) => (
                    <Supervisees visible={visible} parent={Employee} />
                  ),
                },
                {
                  permission: 'event:list',
                  name: 'Routes',
                  dataIndex: 'routes',
                  component: (visible) => (
                    <Routes visible={visible} parent={Employee} />
                  ),
                },
                {
                  permission: 'employee_product_category:list',
                  name: 'Product Categories',
                  dataIndex: 'product_categories',
                  component: (visible) => (
                    <ProductCategories visible={visible} parent={Employee} />
                  ),
                },
                {
                  permission: 'target:list',
                  name: 'Targets',
                  dataIndex: 'targets',
                  component: (visible) => (
                    <EmployeeTargets visible={visible} parent={Employee} />
                  ),
                },
                {
                  permission: 'device:list',
                  name: 'Devices',
                  dataIndex: 'devices',
                  component: (visible) => (
                    <Devices visible={visible} parent={Employee} />
                  ),
                },
                // {
                //   permission: 'organization:list',
                //   name: 'Analytics',
                //   dataIndex: 'analytics',
                //   component: (visible) => (
                //     <EmployeeAnalytics visible={visible} parent={Employee} />
                //   ),
                // },
                {
                  permission: 'visit:list',
                  name: 'Visits',
                  dataIndex: 'visits',
                  component: (visible) => (
                    <Visits visible={visible} parent={Employee} />
                  ),
                },
              ],
            }}
          />
        )}
      </Spin>
    </MainPage>
  );
};

export default Employee;
