import {Col, Form, Input, Row, Select} from "antd"
import useListLoader from "../util/useListLoader";
import {organizationClientApi} from "../api/api";

const StockTransferForm = ({old, parent}) => {

    const [loading, clients] = useListLoader(organizationClientApi.listApi, {
        pageable: {
            page: 0,
            size: 1000,
            sort: 'lastModifiedDate',
            direction: 'DESC',
        },
        entity: {
            parent: {
                id: parent.id,
            }
        }
    }, [parent])

    return <>
        <Row>
            <Col
                xs={24}
            >
                <Form.Item
                    label={'From Inventory ID'}
                    name={['fromInventory', 'id']}
                    hidden={true}
                >
                    <Input/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col
                xs={24}
            >
                <Form.Item
                    label={'To Inventory ID'}
                    name={['toInventory', 'id']}
                >
                    <Select>
                        {
                            clients && clients.content && clients.content.map(client => <Select.Option
                                value={client.child.inventory.id}>{client.child.name}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col
                xs={24}
            >
                <Form.Item
                    label={'Status'}
                    name={['stockTransferStatus']}
                >
                    <Input disabled={true}/>
                </Form.Item>
            </Col>
        </Row>
    </>
}

const StockTransferFormFunction = (old, parent) => <StockTransferForm old={old} parent={parent}/>

export default StockTransferFormFunction