import {Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {productApis} from "../api/api";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ErrorHandler from "../util/ErrorHandler";

const EmployeeTargetForm = ({old, parent}) => {

    const employment = useSelector(state => state.profile.myEmployment)
    const [selectionType, setSelectionType] = useState('date')
    const [products, setProducts] = useState();


    useEffect(() => {
        fetchProducts()
    }, [employment])

    const fetchProducts = () => {
        if (employment)
            productApis.listProductsForEmployee(employment.id).then(response => {
                setProducts(response.data)
            }).catch(error => {
                ErrorHandler(error, 'Failed to get the product !')
            })
    }

    return <>
        <Row>
            <Col xs={24}>
                <Form.Item name={['employeeId']} label={'Employee'} hidden={true}>
                    <Input/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item name={['productId']} label={'Product'}>
                    <Select>
                        {products && products.map(product => <Select.Option
                            value={product.id}>{product.name}</Select.Option>)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item name={'period'} label={'Period'}>
                    <DatePicker.RangePicker picker={selectionType} style={{width: '100%'}}/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item name={'target'} label={'Target'}>
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>
            </Col>
        </Row>
    </>
}

const EmployeeTargetFormFunction = (old, parent) => <EmployeeTargetForm old={old} parent={parent}/>

export default EmployeeTargetFormFunction