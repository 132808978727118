import MainPage from '../core/MainPage'
import DataTable from '../core/DataTable/DataTable'
import { organizationApis } from '../api/api'
import { useDispatch } from 'react-redux'
import { setMarkers } from '../app/markerReducer'
import { useHistory } from 'react-router-dom'
import OrganizationFormFunction from './OrganizationFormFunction'
import { useEffect } from "react";
import { setShowMap } from "../app/windowStateReducer";

const OrganizationsList = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setShowMap(true))
    }, [])

    const dataProcessor = (data) => {
        dispatch(setMarkers(data.content
            .map(organization => ({
                name: organization.name,
                icon: organization.level.id,
                lat: organization.location.lat,
                lng: organization.location.lng,
                onClick: () => history.push(`/organization/${organization.id}`)
            }))))
    }

    return <MainPage
        headerProps={{
            title: 'Organizations'
        }}
    >
        <DataTable
            browse={(record) => history.push(`/organization/${record.id}`)}
            deletable={record => true}
            itemName={'organization'}
            apis={organizationApis}
            defaultFilters={{
                inventory: {
                    managed: false,
                    selfReceive: false,
                    selfDeliver: false,
                    timeZone: 'Asia/Colombo',
                },
                location: {
                    visitingDistance: 5,
                },
            }}
            drawerForm={{
                updatable: true,
                render: OrganizationFormFunction
            }}
            dataProcessor={dataProcessor}
            restToFormMapper={data => ({
                ...data,
                location: data.location && {
                    ...data.location,
                    coordinates: data.location && {
                        lat: data.location.lat,
                        lng: data.location.lng
                    }
                }
            })}
            formToRestMapper={data => ({
                ...data,
                location: {
                    ...data.location,
                    ...data.location.coordinates,
                }
            })}
            columns={[
                {
                    title: 'Name',
                    dataIndex: ['name'],
                    browse: record => history.push(`/organization/${record.id}`)
                },
                {
                    title: 'Level',
                    dataIndex: ['level', 'name'],
                    browse: record => history.push(`/settings/organization-levels`)
                },
                {
                    title: 'Phone Number',
                    dataIndex: ['contact', 'phoneNumber1']
                },
            ]}
        />
    </MainPage>
}

export default OrganizationsList