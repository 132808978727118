import { Select } from "antd"
import { orderItemApis, productApis } from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import { useSelector } from "react-redux"
import useListLoader from "../util/useListLoader";

const OrderItems = ({ parent }) => {
    const employeement = useSelector(state => state.profile.myEmployment)

    const [loadingProducts, products] = useListLoader(productApis.listProductsForEmployee, employeement && employeement.id, [employeement])

    return <DataTable
        itemName={'order_item'}
        defaultFilters={{
            order: {
                id: parent.id
            },
        }}
        editable={true}
        parent={parent}
        apis={orderItemApis}
        columns={[
            {
                title: "Product",
                dataIndex: ["product", "id"],
                type: "select",
                items:
                    products &&
                    products.map((item) => (
                        <Select value={item.id}>{item.name}</Select>
                    )),
                editable: (record) => parent.orderStatus === "DRAFT" && true,
                persistedDataIndex: ["product", "name"],
                rules: [
                    {
                        required: true,
                        message: "Product is required",
                    },
                ],
                width: 300,
            },
            {
                title: "Ordered",
                dataIndex: "orderedQuantity",
                type: "number",
                align: "right",
                editable: (record) => parent.orderStatus === "DRAFT" && true,
                rules: [
                    {
                        required: true,
                        message: "Ordered quantity is required",
                    },
                ],
            },
            {
                title: "Dispatched",
                dataIndex: "dispatchedQuantity",
                type: "number",
                align: "right",
            },
            {
                title: "Delivered",
                dataIndex: "deliveredQuantity",
                type: "number",
                align: "right",
            },
        ]}
        deletable={(record) => record.order.orderStatus === "DRAFT"}
        formToRestMapper={(item, record) => ({
            order: {
                id: record.order.id,
            },
            orderedQuantity: item.orderedQuantity,
            product: {
                id: item.product.id,
            },
        })}
        initialValues={{
            order: {
                id: parent.id,
            },
            orderedQuantity: 0,
            dispatchedQuantity: 0,
            deliveredQuantity: 0,
        }}
    />;
}

export default OrderItems