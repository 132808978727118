import MainPage from '../core/MainPage'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {getProductCategory, productApis} from '../api/api'
import ErrorHandler from '../util/ErrorHandler'
import DataTable from '../core/DataTable/DataTable'
import {Spin} from 'antd'
import {useDispatch} from "react-redux";
import {setShowMap} from "../app/windowStateReducer";

const ProductCategory = () => {

    const dispatch = useDispatch()

    const {id} = useParams()

    const [LoadingItem, setLoadingItem] = useState(false)
    const [Item, setItem] = useState()

    useEffect(() => {
        fetchProductCategory()
    }, [id])

    useEffect(() => {
        dispatch(setShowMap(false))
    }, [])

    const fetchProductCategory = () => {
        setLoadingItem(true)
        getProductCategory(id).then(response => {
            setItem(response.data)
            setLoadingItem(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get product category !')
            setLoadingItem(false)
        })
    }

    return (
        <MainPage
            headerProps={{
                title: Item ? Item.name : "Loading ...",
            }}
            item={Item}
        >
            <Spin spinning={LoadingItem}>
                {Item && <DataTable
                    apis={productApis}
                    defaultFilters={{
                        category: Item,
                    }}
                    editable={true}
                    deletable={record => true}
                    itemName={'product'}
                    parent={Item}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: ['name'],
                            type: 'text',
                            editable: record => true,
                        }
                    ]}
                />}
            </Spin>
        </MainPage>
    );
}

export default ProductCategory