import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {Provider} from 'react-redux'
import store from './app/store'
import {BrowserRouter} from 'react-router-dom'

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
