import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useCallback } from "react";

export const colors = [
    '#0091D5',
    '#EA6A47',
    '#B3C100',
    '#D32D41',
    '#DADADA',
    '#202020',
    '#DBAE51',
]

const RADIAN = Math.PI / 180;

const DataPieChart = ({ structure, data, height, width }) => {
    const renderCustomizedLabel = useCallback(({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${data[0][index].name.substring(0, 9)} ${(percent * 100).toFixed(0)}%`}
            </text>
        );
    }, [data])

    return <ResponsiveContainer
        width={width || '100%'}
        height={height || '100%'}
    >
        <PieChart>
            {structure.items.map((item, index) => <Pie data={data[index]}
                dataKey={item.dataIndex || "value"}
                nameKey={item.name || "name"}
                cx="50%"
                cy="50%"
                innerRadius={((30 / structure.items.length) * (structure.items.length - index)).toString() + '%'}
                outerRadius={((70 / structure.items.length) * (structure.items.length - index)).toString() + '%'}
                opacity={0.5}
                label={true}
                labelLine={false}
            >
                {
                    data.map((data1, index1) => data1.map((data2, index2) => {
                        return <Cell fill={data2.color || colors[index2]} />
                    }))
                }
            </Pie>
            )}
            <Tooltip contentStyle={{ borderRadius: 10, opacity: 0.8 }} />
            <Legend />
        </PieChart>
    </ResponsiveContainer>
}

export default DataPieChart