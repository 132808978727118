import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from 'antd';
import Map from '../core/Map';
import { useEffect, useState } from 'react';
import { organizationLevelApi } from '../api/api';
import ErrorHandler from '../util/ErrorHandler';
import { GithubPicker } from 'react-color';

const OrganizationForm = ({ old }) => {
  const [LoadingOrganizationLevels, setLoadingOrganizationLevels] =
    useState(false);
  const [OrganizationLevels, setOrganizationLevels] = useState({
    content: [],
  });

  useEffect(() => {
    fetchOrganizationLevels();
  }, []);

  const fetchOrganizationLevels = () => {
    setLoadingOrganizationLevels(true);
    organizationLevelApi
      .listApi()
      .then((response) => {
        setOrganizationLevels(response.data);
        setLoadingOrganizationLevels(false);
      })
      .catch((error) => {
        ErrorHandler(error, 'Failed to list organization levels !');
      });
  };

  return (
    <Spin spinning={LoadingOrganizationLevels}>
      <Divider>General</Divider>
      <Row>
        <Col xs={24}>
          <Form.Item label={'Color'} name={'color'} valuePropName={'color'}>
            <GithubPicker
              width="140px"
              triangle="hide"
              colors={['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76']}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item
            label={'Name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Name is required !',
              },
            ]}
          >
            <Input placeholder={'Name'} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item
            label={'Level'}
            name={['level', 'id']}
            rules={[
              {
                required: true,
                message: 'Level is required !',
              },
            ]}
          >
            <Select placeholder={'Level'}>
              {OrganizationLevels.content.map((level) => (
                <Select.Option value={level.id}>{level.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider>Contact</Divider>
      {old && (
        <Row>
          <Col xs={24}>
            <Form.Item
              hidden={true}
              noStyle
              label={'ID'}
              name={['contact', 'id']}
            >
              <Input placeholder={'ID'} disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={24}>
          <Form.Item
            label={'Email'}
            name={['contact', 'email']}
            rules={[
              {
                type: 'email',
                message: 'Could be a valid email address !',
              },
            ]}
          >
            <Input placeholder={'Email'} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item label={'Phone'}>
            <Input.Group>
              <Form.Item noStyle name={['contact', 'phoneNumber1']}>
                <Input
                  placeholder={'Phone Number 1'}
                  style={{
                    width: '50%',
                  }}
                />
              </Form.Item>
              <Form.Item noStyle name={['contact', 'phoneNumber2']}>
                <Input
                  placeholder={'Phone Number 2'}
                  style={{
                    width: '50%',
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider>Location</Divider>
      {old && (
        <Row>
          <Col xs={24}>
            <Form.Item
              hidden={true}
              noStyle
              label={'ID'}
              name={['location', 'id']}
            >
              <Input placeholder={'ID'} disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={24}>
          <Form.Item label={'Address'}>
            <Input.Group>
              <Form.Item noStyle name={['location', 'addressLine1']}>
                <Input
                  placeholder={'Address Line 1'}
                  style={{
                    width: '50%',
                  }}
                />
              </Form.Item>
              <Form.Item noStyle name={['location', 'addressLine2']}>
                <Input
                  placeholder={'Address Line 2'}
                  style={{
                    width: '50%',
                  }}
                />
              </Form.Item>
            </Input.Group>
            <Input.Group>
              <Form.Item noStyle name={['location', 'addressLine3']}>
                <Input
                  placeholder={'Address Line 3'}
                  style={{
                    width: '50%',
                  }}
                />
              </Form.Item>
              <Form.Item noStyle name={['location', 'addressLine4']}>
                <Input
                  placeholder={'Address Line 4'}
                  style={{
                    width: '50%',
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item label={'Location'} name={['location', 'coordinates']}>
            <Map
              mapContainerStyle={{
                width: '100%',
                height: 300,
                borderRadius: 10,
                overflow: 'hidden',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item label={'Location'}>
            <Input.Group>
              <Form.Item noStyle name={['location', 'coordinates', 'lat']}>
                <Input
                  placeholder={'Latitude'}
                  style={{
                    width: '50%',
                  }}
                />
              </Form.Item>
              <Form.Item noStyle name={['location', 'coordinates', 'lng']}>
                <Input
                  placeholder={'Longitude'}
                  style={{
                    width: '50%',
                  }}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
      <Divider>Route</Divider>
      <Row>
        <Col xs={24}>
          <Form.Item
            label={'Visiting Distance'}
            name={['location', 'visitingDistance']}
          >
            <InputNumber
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider>Inventory</Divider>
      <Row>
        <Col xs={8}>
          <Form.Item
            label={'Managed Inventory'}
            name={['inventory', 'managed']}
            valuePropName={'checked'}
          >
            <Checkbox />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            label={'Self Deliver'}
            name={['inventory', 'selfDeliver']}
            valuePropName={'checked'}
          >
            <Checkbox />
          </Form.Item>
        </Col>
        <Col xs={8}>
          <Form.Item
            label={'Self Receive'}
            name={['inventory', 'selfReceive']}
            valuePropName={'checked'}
          >
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item label={'Time Zone'} name={['inventory', 'timeZone']}>
            <Input
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Spin>
  );
};

const OrganizationFormFunction = (old) => <OrganizationForm old={old} />;

export default OrganizationFormFunction;
