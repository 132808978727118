import {employeeParentEventApis, listEmployeeEventForEmployeAndMonth, postEmployeeParentEvent} from '../api/api'
import {useHistory} from "react-router-dom"
import DataCalendar from "../core/DataCalendar"
import RouteEventFormFunction from "./RouteEventFormFunction"
import moment from 'moment'
import {useEffect} from "react";
import {setShowMap} from "../app/windowStateReducer";
import {useDispatch} from "react-redux";

const Routes = ({parent, visible}) => {

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    return <>
        <DataCalendar
            browse={data => history.push(`/route/${data.route.id}`)}
            parent={parent}
            drawerForm={{
                render: RouteEventFormFunction
            }}
            initialValues={{
                employeementId: parent.id
            }}
            apis={{
                ...employeeParentEventApis,
                listApi: listEmployeeEventForEmployeAndMonth
            }}
            postApi={postEmployeeParentEvent}
            formToRestMapper={data => {
                return ({
                    ...data,
                    parentEvent: {
                        ...data.parentEvent,
                        endDateTime: data.parentEvent.endDateTime._d.toISOString(),
                        children: [
                            {
                                ...data.parentEvent.child,
                                name: data.parentEvent.name,
                                startDateTime: data.parentEvent.child.startDateTime[0]._d.toISOString(),
                                endDateTime: data.parentEvent.child.startDateTime[1]._d.toISOString(),
                            }
                        ]
                    }
                })
            }}
            restToFormMapper={data => ({
                ...data,
                event: {
                    ...data.event,
                    duration: [
                        moment(data.event.startDateTime),
                        moment(data.event.endDateTime),
                    ]
                }
            })}
        />
    </>
}

export default Routes