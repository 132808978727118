import {Spin} from "antd"
import {titleApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setShowMap} from "../app/windowStateReducer";

const Titles = ({parent}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setShowMap(false))
    })

    return <Spin spinning={false}>
        <DataTable
            itemName={'title'}
            apis={titleApis}
            defaultFilters={{
                level: parent,
            }}
            deletable={record => true}
            editable={true}
            itemName={'title'}
            parent={parent}
            columns={[
                {
                    title: 'Name',
                    dataIndex: ['name'],
                    type: 'text',
                    editable: record => true,
                },
            ]}
        />
    </Spin>
}

export default Titles