import {Spin} from "antd"
import {employeeLevelApis, getEmployeeLevel} from "../api/api"
import MainPage from '../core/MainPage'
import {useEffect, useState} from 'react'
import {useParams} from "react-router"
import {Button} from "antd/lib/radio"
import {MoreOutlined} from "@ant-design/icons"
import Titles from "./Titles"
import EmployeeLevelFormFunction from "../Settings/EmployeeLevelFormFunction";
import ItemDrawer from "../core/ItemDrawer";
import ErrorHandler from "../util/ErrorHandler";

const EmployeeLevel = () => {

    const {id} = useParams()

    const [LoadingLevel, setLoadingLevel] = useState(false)
    const [Level, setLevel] = useState(undefined)

    const [ShowDrawer, setShowDrawer] = useState(false)

    useEffect(() => {
        fetchLevel()
    }, [id])

    const fetchLevel = () => {
        setLoadingLevel(true)
        getEmployeeLevel(id)
            .then(response => {
                setLevel(response.data)
                setLoadingLevel(false)
            })
            .catch(error => {
                ErrorHandler(error, 'Failed to get employee level !')
                setLoadingLevel(false)
            })
    }

    return <Spin
        spinning={LoadingLevel}
    >
        {Level && <ItemDrawer
            apis={employeeLevelApis}
            old={true}
            item={Level}
            itemName={'employee level'}
            onClose={(updated) => {
                updated && fetchLevel()
                setShowDrawer(false)
            }}
            visible={ShowDrawer}
            drawerFormUpdatable={true}
        >
            {EmployeeLevelFormFunction(true)}
        </ItemDrawer>}
        <MainPage
            headerProps={{
                title: Level ? Level.name : 'Loading ...',
                subTitle: 'Titles',
                extra: [
                    <Button
                        icon={<MoreOutlined/>}
                        onClick={() => setShowDrawer(true)}
                    >
                        MORE
                    </Button>
                ]
            }}
        >
            {Level && <Titles parent={Level}/>}
        </MainPage>

    </Spin>

}

export default EmployeeLevel