import {DatePicker, Form, Input, Select, Spin} from "antd"
import {useEffect, useState} from "react"
import ErrorHandler from "../util/ErrorHandler"
import {organizationRouteApis} from "../api/api"

const RouteEventForm = ({old, parent}) => {
    const [LoadingOrganizationRoutes, setLoadingOrganizationRoutes] = useState(false)
    const [OrganizationRoutes, setOrganizationRoutes] = useState({
        content: [],
    })

    useEffect(() => {
        fetchOrganizaitonRoutes()
    }, [parent, old])

    const fetchOrganizaitonRoutes = () => {
        setLoadingOrganizationRoutes(true)
        organizationRouteApis.listApi({
            pageable: {
                page: 0,
                size: 1000,
                direction: 'DESC',
                sort: 'lastModifiedDate',
            },
            entity: {
                organizationId: parent.organization.id,
            }
        }).then(response => {
            setOrganizationRoutes(response.data)
            setLoadingOrganizationRoutes(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get organization routes !')
            setLoadingOrganizationRoutes(false)
        })
    }

    return <Spin spinning={LoadingOrganizationRoutes}>
        <Form.Item
            label={'Employee ID'}
            name={'employeementId'}
        >
            <Input disabled={true}/>
        </Form.Item>
        {!old && <Form.Item
            label={'Route'}
            name={'parentRouteId'}
        >
            <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a route"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {OrganizationRoutes && OrganizationRoutes.content.map(item => <Select.Option
                    value={item.parentRoute.id}>{item.parentRoute.name}</Select.Option>)}
            </Select>
        </Form.Item>}
        <Form.Item
            label={'Name'}
            name={old ? ['event', 'name'] : ['parentEvent', 'name']}
        >
            <Input disabled={old}/>
        </Form.Item>
        {!old && <Form.Item
            label={'Repeat'}
            name={['parentEvent', 'repeatType']}
        >
            <Select>
                <Select.Option value={'DAILY'}>Daily</Select.Option>
                <Select.Option value={'WEEKLY'}>Weekly</Select.Option>
                <Select.Option value={'MONTHLY'}>Monthly</Select.Option>
                <Select.Option value={'YEARLY'}>Yearly</Select.Option>
            </Select>
        </Form.Item>}
        {!old && <Form.Item
            label={'End Date'}
            name={['parentEvent', 'endDateTime']}
        >
            <DatePicker
                style={{
                    width: '100%',
                }}
            />
        </Form.Item>}
        <Form.Item
            label={'Duration'}
            name={old ? ['event', 'duration'] : ['parentEvent', 'child', 'startDateTime']}
        >
            <DatePicker.RangePicker showTime={true}/>
        </Form.Item>
    </Spin>
}

const RouteEventFormFunction = (old, parent) => <RouteEventForm old={old} parent={parent}/>

export default RouteEventFormFunction