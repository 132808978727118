import {createSlice} from '@reduxjs/toolkit'

export const markerReducer = createSlice({
    name: 'marker',
    initialState: {
        markers: [],
        routes: [],
        levels: [],
    },
    reducers: {
        setMarkers: (state, action) => {
            let markers = action.payload.map(marker => {
                let selectedLevel = state.levels.filter(level => level.id === marker.icon)

                if(selectedLevel.length > 0) {
                    return {
                        lat: marker.lat,
                        lng: marker.lng,
                        icon: selectedLevel[0].icon,
                        name: marker.name,
                        onClick: marker.onClick,
                    }
                }
                
            })
            state.markers = markers
        },
        addMarkers: (state, action) => {
            let markers = action.payload.map(marker => {
                let selectedLevel = state.levels.filter(level => level.id === marker.icon)

                if(selectedLevel.length > 0) {
                    return {
                        lat: marker.lat,
                        lng: marker.lng,
                        icon: selectedLevel[0].icon,
                        name: marker.name,
                    }
                }
                
            })
            state.markers = [...state.markers, ...markers];
        },
        setRoutes: (state, action) => {
            state.routes = action.payload
        },
        setLevels: (state, action) => {
            state.levels = action.payload
        }
    },
})

export const {setMarkers, setRoutes, addMarkers, setLevels} = markerReducer.actions

export default markerReducer.reducer