import { Brush, CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine } from "recharts";
import { PureComponent } from "react";

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-25)">
                    {payload.value}
                </text>
            </g>
        );
    }
}

const DataLineChart = ({ xAxisLabel, yAxisLabel, xAxisDataKey, structure, data, xRefLines, yRefLines }) => {
    return <ResponsiveContainer width={'100%'} height={'100%'}>
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 60 }}>
            <Legend verticalAlign="top" height={36} iconType={'square'} />
            <XAxis interval={0} height={50} angle={90} dataKey={xAxisDataKey || 'name'} dx={15}
                dy={50} tick={<CustomizedAxisTick />}>
                <Label value={xAxisLabel} position="insideBottom" offset={10} />
            </XAxis>
            <YAxis width={70}>
                <Label angle={-90} value={yAxisLabel} position="insideLeft" />
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {xRefLines && xRefLines.map((item) => {
                return (
                    <ReferenceLine
                        x={item.x}
                        stroke={item.stroke}
                        label={item.label}
                    />
                )
            })}
            {yRefLines && yRefLines.map((item) => {
                return (
                    <ReferenceLine
                        y={item.y}
                        label={item.label}
                        stroke={item.stroke}
                    />
                )
            })}

            {
                structure.items.map(item => <Line type="monotone" dataKey={item.dataIndex} stroke={item.color}
                    fillOpacity={0.5} fill={item.color} />)
            }
        </LineChart>
    </ResponsiveContainer>
}

export default DataLineChart