import {Button, Card, Modal, Space} from "antd";
import {PrinterTwoTone, SettingTwoTone} from "@ant-design/icons";
import {useState} from "react";
import * as htmlToImage from 'html-to-image';
import {v4 as uuidv4} from 'uuid';

const ChartCard = ({children, title, height, width, settingsForm, onOk}) => {

    const [showModal, setShowModal] = useState(false)
    const [id, setId] = useState(uuidv4())

    const onCapture = () => {
        htmlToImage.toPng(document.getElementById(id))
            .then(function (dataUrl) {
                saveAs(dataUrl, `${title || 'exported-chart'}.png`);
            });
    }

    const saveAs = (blob, fileName) => {
        var elem = window.document.createElement('a');
        elem.href = blob
        elem.download = fileName;
        elem.style = 'display:none;';
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === 'function') {
            elem.click();
        } else {
            elem.target = '_blank';
            elem.dispatchEvent(new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            }));
        }
        URL.revokeObjectURL(elem.href);
        elem.remove()
    }

    return <>
        <Modal
            title={'Settings'}
            visible={showModal}
            onCancel={() => setShowModal(false)}
            onOk={() => {
                onOk()
                setShowModal(false)
            }}
        >
            {settingsForm}
        </Modal>
        <Card
            hoverable={true}
            id={id}
            extra={<Space>
                {settingsForm && <Button
                    onClick={() => setShowModal(true)}
                    icon={<SettingTwoTone/>}
                />}
                <Button
                    icon={<PrinterTwoTone/>}
                    onClick={onCapture}
                />
            </Space>}
            title={title || 'Title'}
            bodyStyle={{
                width: width || '100%',
                height: height || 'calc(100% - 60px)',
                padding: 10,
                overflow: 'auto'
            }}
            // headStyle={{
            //     border: 'none',
            // }}
            style={{
                width: '100%',
                height: '100%',
                // border: '1px solid lightblue',
                // background: 'radial-gradient(circle, rgba(165,237,247,0.07044824765843838) 0%, rgba(92,192,213,0.0760504885547969) 100%)',
                // border: 'none',
                // borderRadius: 20,
                // backgroundColor: '#fafafa',
            }}
        >
            {children}
        </Card>
    </>

}

export default ChartCard