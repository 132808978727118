import React, { useEffect, useState } from 'react';
import './App.less';
import { Layout, Result, Spin } from 'antd';
import { Route, Switch } from 'react-router-dom';
import Page from './core/Page';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from './app/profile';
import { BaseFrontEndUrl } from './api/api';

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let keycloak = Keycloak(BaseFrontEndUrl + '/keycloak.json');

    keycloak
      .init({
        onLoad: 'login-required',
      })
      .then(function (authenticated) {
        if (!authenticated) {
          setError(true);
          setLoading(false);
          return;
        }

        setInterval(() => {
          keycloak
            .updateToken(-1)
            .then(function (refreshed) {
              if (refreshed) {
                axios.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${keycloak.token}`;
                console.log('Token was successfully refreshed');
              } else {
                console.log('Token is still valid');
              }
            })
            .catch(function () {
              console.log(
                'Failed to refresh the token, or the session has expired'
              );
            });
        }, 60000);

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${keycloak.token}`;

        keycloak.loadUserInfo().then((userInfo) => {
          dispatch(setUser(userInfo));
        });
        setLoading(false);
      })
      .catch(function () {
        setError(true);
        setLoading(false);
      });
  }, []);

  return loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Spin />
    </div>
  ) : error ? (
    <Result
      status="warning"
      title="System configuration is not complete."
      subTitle="Please contact system administrator for more details !"
    />
  ) : (
    <Layout>
      <Switch>
        <Route path="/">
          <Page />
        </Route>
      </Switch>
    </Layout>
  );
};

export default App;
