import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Image,
} from 'antd';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';

const EditableCell = ({ children, column, record, ...props }) => {
  const [searchText, setSearchText] = useState('');
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (searchText === '') return;

    column &&
      column.fetch &&
      column
        .fetch(searchText)
        .then((response) => setItems(column.fetchToItems(response)));
  }, [searchText]);

  // !IMPORTANT ::: Column is undefined for drag handle and more icon button columns.
  if (!column) {
    return <td {...props}>{children}</td>;
  }

  return (
    <td {...props}>
      <Form.Item
        name={
          column.type === 'select' && !column.editable(record)
            ? column.persistedDataIndex
            : column.dataIndex
        }
        style={{
          margin: 0,
        }}
        rules={column.rules}
        valuePropName={column.type === 'checkbox' ? 'checked' : undefined}
      >
        {column.type === 'select' ? (
          !column.editable(record) && column.persistedDataIndex ? (
            <Input disabled={true} />
          ) : (
            <Select
              onSearch={(searchText) => setSearchText(searchText)}
              disabled={!column.editable(record)}
              showSearch
              style={{ width: '100%' }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {column.fetch ? items : column.items}
            </Select>
          )
        ) : column.type === 'number' ? (
          <InputNumber
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', textAlign: 'right' }}
            disabled={column.editable ? !column.editable(record) : true}
          />
        ) : column.type === 'date-range' ? (
          <DatePicker.RangePicker />
        ) : column.type === 'text' ? (
          <Input autoFocus={true} disabled={!column.editable(record)} />
        ) : column.type === 'checkbox' ? (
          <Checkbox />
        ) : column.type === 'icon' ? (
          <ImageUploading multiple={false} dataURLKey="data_url">
            {({ imageList, onImageUpload, onImageRemove }) => (
              <div>
                {imageList.length > 0 ? (
                  imageList.map((image, index) => (
                    <div key={index}>
                      <Image
                        src={image['data_url']}
                        preview={false}
                        style={{
                          height: 30,
                          width: 30,
                          borderRadius: '8px',
                          cursor: 'pointer',
                        }}
                        onClick={() => onImageRemove(index)}
                      />
                    </div>
                  ))
                ) : (
                  <Button onClick={onImageUpload} icon={<UploadOutlined />} />
                )}
              </div>
            )}
          </ImageUploading>
        ) : (
          <td {...props}>{children}</td>
        )}
      </Form.Item>
    </td>
  );
};

export default EditableCell;
