import { Button, Input, Space, Spin } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Autocomplete,
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  useJsApiLoader,
  MarkerClusterer,
  HeatmapLayer,
} from '@react-google-maps/api'
import { AimOutlined, HeatMapOutlined } from '@ant-design/icons'

import mapStyles from './mapStyles.json'

const libraries = ["places", "visualization"]


const Map = ({ mapContainerStyle, center, zoom, value, onChange, heatMapData }) => {
  const [AutoCompleter, setAutoCompleter] = useState(null)

  const markers = useSelector(state => state.marker.markers)
  const routes = useSelector(state => state.marker.routes)

  const [Origin, setOrigin] = useState()
  const [Destination, setDestination] = useState()
  const [Waypoints, setWaypoints] = useState()
  const [Directions, setDirections] = useState()

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBKuuRgQUUZS2d3Zs6fBuiKxC8GurQp--I",
    libraries: libraries,
  })

  const DirectionsRendererMemo = useMemo(() => Origin && Destination && <DirectionsService
    // required
    options={{
      destination: Destination,
      origin: Origin,
      travelMode: 'DRIVING',
      waypoints: Waypoints,
    }}
    // required
    callback={(response) => {
      setDirections(response)
    }}
    // optional
    onLoad={directionsService => {
    }}
    // optional
    onUnmount={directionsService => {
    }}
  />, [Origin, Destination, Waypoints])


  useEffect(() => {

    let length = routes.length

    if (length > 1) {
      setOrigin({
        location: routes[0],
      })
      setDestination({
        location: routes[length - 1],
      })
    }
    if (length > 2) {
      let end = routes.length - 2
      let newRoutes = [...routes]
      setWaypoints(newRoutes.splice(1, end).map(routeLocation => ({
        location: {
          lat: routeLocation.lat,
          lng: routeLocation.lng,
        },
      })))
    } else {
      setWaypoints(undefined)
    }
  }, [routes])


  const onLoad = (autocomplete) => {
    setAutoCompleter(autocomplete)
  }

  const onPlaceChanged = () => {
    if (AutoCompleter) {
      let place = AutoCompleter.getPlace()
      onChange && onChange({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      })
    }
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    <div>
      <Space
        style={{
          margin: 10,
          position: 'absolute',
          zIndex: 1000,
          right: '20%',
        }}
      >
        <Button icon={<AimOutlined />} />
        <Button icon={<HeatMapOutlined />} />
      </Space>
      <GoogleMap
        options={{
          styles: mapStyles,
        }}
        onClick={(e) =>
          onChange &&
          onChange({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
          })
        }
        // onTilesLoaded={() => setLoading(false)}
        mapContainerStyle={mapContainerStyle}
        center={
          center
            ? center
            : {
              lat: value ? value.lat : 6.9279753,
              lng: value ? value.lng : 79.8805333,
            }
        }
        zoom={zoom ? zoom : 10}
      >
        {heatMapData && <HeatmapLayer
          options={{
            radius: 20,
          }}
          onLoad={onLoad}
          // onUnmount={onUnmount}
          data={heatMapData.map(data => ({
            location: new window.google.maps.LatLng(data.lat, data.lng), weight: data.weight
          }))}
        />}
        {onChange && (
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <Input
              style={{
                margin: "60px 10px 0px 10px",
                width: "calc(100% - 20px)",
              }}
            />
          </Autocomplete>
        )}
        {value && (
          <Marker
            position={{
              lat: value.lat,
              lng: value.lng,
            }}
          />
        )}
        <MarkerClusterer>
          {clusterer => markers &&
            markers.map(
              (marker) =>
                marker.lat &&
                marker.lng && (
                  <Marker
                    onClick={marker.onClick}
                    icon={{
                      url: marker.icon,
                      scaledSize: heatMapData ? {height: 10, width: 10} : { height: 40, width: 40 },
                      labelOrigin: new window.google.maps.Point(20, 40)
                    }}
                    position={{
                      lat: marker.lat,
                      lng: marker.lng,
                    }}
                    label={{
                      text: marker.name,
                      className: 'map-marker-label',
                    }}
                    clusterer={clusterer}
                  />
                )
            )}
        </MarkerClusterer>
        {DirectionsRendererMemo}
        {Directions && (
          <DirectionsRenderer
            options={{
              directions: Directions,
            }}
          />
        )}
      </GoogleMap>
    </div>
  ) : (
    <Spin />
  );
}

export default Map