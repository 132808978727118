import moment from "moment"
import {useHistory} from "react-router-dom"
import {orderApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setShowMap} from "../app/windowStateReducer";

const ClientOrders = ({parent, updatable, visible}) => {

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    return <DataTable
        apis={orderApis}
        defaultFilters={{
            fromInventory: {
                id: parent.inventory.id,
            }
        }}
        browse={record => history.push(`/client-orders/${record.id}`)}
        itemName={'order'}
        parent={parent}
        columns={[
            {
                title: 'Date',
                dataIndex: ['createdDate'],
                render: (text, record, index) => moment(record.createdDate).format('MMMM Do YYYY'),
                browse: record => history.push(`/client-orders/${record.id}`),
            },
            {
                title: 'Client',
                dataIndex: ['toOrganization', 'name'],
                browse: record => history.push(`/organization/${record.toOrganization.id}`)
            },
            {
                title: 'Order Status',
                dataIndex: ['orderStatus'],
            },
            {
                title: 'Dispatch Status',
                dataIndex: ['dispatchStatus'],
            },
            {
                title: 'Delivery Status',
                dataIndex: ['deliveryStatus'],
            },
        ]}
        // expandable={{
        //     indentSize: 0,
        //     expandedRowRender: (record, index, indent, expanded) => <TransferItems parent={record}/>
        // }}
    />
}

export default ClientOrders