import { Spin } from 'antd';
import { employeeVisitApis } from '../api/api';
import DataTable from '../core/DataTable/DataTable';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMarkers } from '../app/markerReducer';
import { setShowMap } from '../app/windowStateReducer';
import moment from 'moment';

const Visits = ({ parent, visible }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) dispatch(setShowMap(true));
  }, [visible]);

  const addToMap = (data) => {
    dispatch(
      setMarkers(
        data.content.map((client) => ({
          ...client.organization?.location,
          name: client.organization?.name,
          icon: client.organization?.level.id,
        }))
      )
    );
  };

  return (
    <Spin spinning={false}>
      <DataTable
        defaultFilters={{
          employeementId: parent.id,
        }}
        deletable={(record) => false}
        editable={false}
        apis={employeeVisitApis}
        dataProcessor={addToMap}
        browse={(record) =>
          history.push(`/organization/${record.organization?.id}`)
        }
        itemName={'visit'}
        parent={parent}
        columns={[
          {
            title: 'Name',
            dataIndex: ['organization', 'name'],
          },
          {
            title: 'Level',
            dataIndex: ['organization', 'level', 'name'],
          },
          {
            title: 'Date',
            render: (text, record, index) =>
              moment(record.visit.dateTime).format('MMMM Do YYYY'),
          },
        ]}
      />
    </Spin>
  );
};

export default Visits;
