import MainPage from '../core/MainPage';
import DataTable from '../core/DataTable/DataTable';
import {employeeLevelApis} from '../api/api';
import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setShowMap} from "../app/windowStateReducer";

const EmployeeLevels = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(setShowMap(false))
    }, [])

    return <MainPage
        headerProps={{
            title: 'Employee Levels',
        }}
    >
        <DataTable
            deletable={record => true}
            editable={true}
            browse={(record) => history.push(`/employee-level/${record.id}`)}
            drag={true}
            apis={employeeLevelApis}
            columns={[
                {
                    dataIndex: ['name'],
                    title: 'Name',
                    type: 'text',
                    editable: record => true,
                },
            ]}
            itemName={'employee_level'}
        />
    </MainPage>
}

export default EmployeeLevels