import { Button, Descriptions, Spin } from 'antd';
import { employeeEventApis, getEmployeeEventForRoute } from '../api/api';
import MainPage from '../core/MainPage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import ErrorHandler from '../util/ErrorHandler';
import RouteLocations from './RouteLocations';
import moment from 'moment';
import TabSet from '../core/TabSet';
import { useDispatch } from 'react-redux';
import { setShowMap } from '../app/windowStateReducer';
import { DeleteOutlined } from '@ant-design/icons';
import { employeeParentEventApis } from '../api/api';

const RoutePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [LoadingItem, setLoadingItem] = useState(false);
  const [Item, setItem] = useState(undefined);

  useEffect(() => {
    dispatch(setShowMap(true));
    fetchItem();
  }, [id]);

  const fetchItem = () => {
    setLoadingItem(true);
    getEmployeeEventForRoute(id)
      .then((response) => {
        setItem(response.data);
        setLoadingItem(false);
      })
      .catch((error) => {
        ErrorHandler(error, 'Failed to get route !');
        setLoadingItem(false);
      });
  };

  return (
    <MainPage
      headerProps={{
        title: Item ? Item.event.name : 'Loading ...',
        extra: [
          <Button
            icon={<DeleteOutlined />}
            onClick={() =>
              employeeEventApis.deleteApi(Item && Item.id).then((response) => {
                history.goBack();
              })
            }
          >
            {'DELETE THIS ONLY'}
          </Button>,
          <Button
            disabled={!(Item && Item.employeeParentEvent)}
            icon={<DeleteOutlined />}
            onClick={() =>
              employeeParentEventApis
                .deleteApi(Item && Item.employeeParentEvent.id)
                .then((response) => {
                  history.goBack();
                })
            }
          >
            {'DELETE FUTURE EVENTS'}
          </Button>,
        ],
      }}
      headerContent={
        Item && (
          <Descriptions
            size={'small'}
            column={{ xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label={'Start'}>
              {moment(Item.event.startDateTime).format(
                'dddd, MMMM Do YYYY, h:mm a'
              )}
            </Descriptions.Item>
            <Descriptions.Item label={'End'}>
              {moment(Item.event.endDateTime).format(
                'dddd, MMMM Do YYYY, h:mm a'
              )}
            </Descriptions.Item>
            <Descriptions.Item label={'Employee'}>
              <Button
                type={'link'}
                size={'small'}
                onClick={() =>
                  history.push(`/employee/${Item.employeement.id}`)
                }
              >
                {Item.employeement.user.firstName +
                  ' ' +
                  Item.employeement.user.lastName}
              </Button>
            </Descriptions.Item>
            <Descriptions.Item label={'Parent Route'}>
              <Button
                type={'link'}
                size={'small'}
                onClick={() =>
                  history.push(`/organization-route/${Item.route.route.id}`)
                }
              >
                {Item.route.route.name}
              </Button>
            </Descriptions.Item>
          </Descriptions>
        )
      }
    >
      <Spin
        spinning={LoadingItem}
        style={{
          width: '100%',
          minHeight: 400,
        }}
      >
        {Item && (
          <TabSet
            routes={{
              default: 'locations',
              routes: [
                {
                  permission: 'route_location:list',
                  name: 'Locations',
                  dataIndex: 'locations',
                  component: (visible) => (
                    <RouteLocations visible={true} event={Item} />
                  ),
                },
              ],
            }}
          />
        )}
      </Spin>
    </MainPage>
  );
};

export default RoutePage;
