import {Button, Dropdown, Form, Menu, Space} from "antd";
import {useState} from "react";
import ErrorHandler from "../../util/ErrorHandler";
import {
    ClockCircleTwoTone,
    DeleteTwoTone,
    InfoCircleTwoTone,
    MoreOutlined,
    RightOutlined,
    SaveTwoTone
} from "@ant-design/icons";
import {sortableElement} from "react-sortable-hoc";

const SortableItem = sortableElement((props) => <tr {...props} />);

const DraggableBodyRow = ({
                              browse,
                              showDrawer,
                              editable,
                              onHistory,
                              deletable,
                              setForm,
                              record,
                              fullData,
                              index,
                              putApi,
                              postApi,
                              deleteApi,
                              restToFormMapper,
                              formToRestMapper,
                              disableActions = false,
                              ...restProps
                          }) => {
    const [form] = Form.useForm();
    const [SaveLoading, setSaveLoading] = useState(false);
    const [DeleteLoading, setDeleteLoading] = useState(false);

    const onFinish = (item) => {
        setSaveLoading(true);
        if (record.temp) {
            postApi(
                formToRestMapper
                    ? formToRestMapper(
                        {
                            ...record,
                            ...item,
                            id: undefined,
                        },
                        record
                    )
                    : {
                        ...record,
                        ...item,
                        id: undefined,
                    }
            ).then(
                (response) => setSaveLoading(false),
                () => setSaveLoading(false)
            );
        } else {
            setSaveLoading(true);
            putApi(
                record.id,
                formToRestMapper ? formToRestMapper(item, record) : item
            )
                .then((response) => {
                    setSaveLoading(false);
                })
                .catch((error) => {
                    ErrorHandler(error, "Failed to update the line !");
                    setSaveLoading(false);
                });
        }
    };

    const onDelete = () => {
        setDeleteLoading(true);
        deleteApi().then(
            (response) => {
                setDeleteLoading(false);
            },
            () => setDeleteLoading(false)
        );
    };

    return (
        <Form
            component={false}
            form={form}
            initialValues={restToFormMapper ? restToFormMapper(record) : record}
            onFinish={onFinish}
        >
            <SortableItem
                index={index}
                className={restProps.className}
                data-row-key={restProps["data-row-key"]}
            >
                {restProps.children &&
                !restProps.children.props && [
                    ...restProps.children.slice(0, disableActions ? restProps.children.length : restProps.children.length - 1),
                    <td>
                        <Space>
                            {editable && (
                                <Button
                                    ghost={false}
                                    icon={<SaveTwoTone twoToneColor={record.temp && "green"}/>}
                                    onClick={() => form.submit()}
                                    loading={SaveLoading}
                                />
                            )}
                            {(showDrawer || browse) && !record.temp && (
                                <Button
                                    onClick={() => (browse ? browse(record) : showDrawer)}
                                    icon={<RightOutlined/>}
                                />
                            )}
                            {!disableActions && <Dropdown
                                overlay={
                                    <Menu>
                                        {((deletable && deletable(record)) || record.temp) && (
                                            <Menu.Item
                                                key="1"
                                                icon={<DeleteTwoTone twoToneColor={"red"}/>}
                                                onClick={onDelete}
                                                loading={DeleteLoading}
                                            >
                                                Delete
                                            </Menu.Item>
                                        )}
                                        <Menu.Item
                                            key="2"
                                            icon={<ClockCircleTwoTone twoToneColor={"green"}/>}
                                            onClick={() => onHistory(record)}
                                        >
                                            History
                                        </Menu.Item>
                                        <Menu.Item
                                            key="3"
                                            icon={<InfoCircleTwoTone twoToneColor={"blue"}/>}
                                            onClick={() => fullData(record)}
                                        >
                                            Info
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button icon={<MoreOutlined/>}/>
                            </Dropdown>}
                        </Space>
                    </td>,
                ]}
            </SortableItem>
        </Form>
    );
};

export default DraggableBodyRow