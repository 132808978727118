import axios from "axios";

// export const BaseUrl = 'http://localhost'
// export const BaseUrl = 'https://wnvknjsd.salesreckon.com'
export const BaseUrl = "https://uat.tcgsdm.tokyocement.com";
export const BaseFrontEndUrl = "https://uat.tcgsdm.tokyocement.com";
// export const BaseFrontEndUrl = "http://localhost:3000";
// export const BaseFrontEndUrl = 'https://wnvknjsd.salesreckon.com'
// export const BaseUrl = 'https://' + process.env.REACT_APP_DOMAIN_NAME
export const RealmName = "tc-uat";

//------------------------------------------------------------------------------------------------------------------------------------

export const listOrganizationLevels = (request) =>
  axios.get(`${BaseUrl}/om/organization-levels`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postOrganizationLevel = (data) =>
  axios.post(`${BaseUrl}/om/organization-levels`, data);
export const getOrganizationLevel = (id) =>
  axios.get(`${BaseUrl}/om/organization-levels/${id}`);
export const putOrganizationLevel = (id, data) =>
  axios.put(`${BaseUrl}/om/organization-levels/${id}`, data);
export const deleteOrganizationLevel = (id) =>
  axios.delete(`${BaseUrl}/om/organization-levels/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listTitles = (request) =>
  axios.get(`${BaseUrl}/om/titles`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listTitlesByLevel = (request, id) =>
  axios.get(`${BaseUrl}/om/titles/level/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postTitle = (data) => axios.post(`${BaseUrl}/om/titles`, data);
export const getTitle = (id) => axios.get(`${BaseUrl}/om/titles/${id}`);
export const putTitle = (id, data) =>
  axios.put(`${BaseUrl}/om/titles/${id}`, data);
export const deleteTitle = (id) => axios.delete(`${BaseUrl}/om/titles/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listEmployeeLevels = (request) =>
  axios.get(`${BaseUrl}/om/employee-levels`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postEmployeeLevel = (data) =>
  axios.post(`${BaseUrl}/om/employee-levels`, data);
export const getEmployeeLevel = (id) =>
  axios.get(`${BaseUrl}/om/employee-levels/${id}`);
export const putEmployeeLevel = (id, data) =>
  axios.put(`${BaseUrl}/om/employee-levels/${id}`, data);
export const deleteEmployeeLevel = (id) =>
  axios.delete(`${BaseUrl}/om/employee-levels/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const postContact = (data) => axios.post(`${BaseUrl}/om/contacts`, data);
export const putContact = (id, data) =>
  axios.put(`${BaseUrl}/om/contacts/${id}`, data);

//------------------------------------------------------------------------------------------------------------------------------------

export const postLocation = (data) =>
  axios.post(`${BaseUrl}/om/locations`, data);
export const putLocation = (id, data) =>
  axios.put(`${BaseUrl}/om/locations/${id}`, data);

//------------------------------------------------------------------------------------------------------------------------------------

export const getOrganization = (id) =>
  axios.get(`${BaseUrl}/orem/organizations/${id}`);
export const postOrganization = (data) =>
  axios.post(`${BaseUrl}/orem/organizations`, data);
export const listOrganizations = (request) =>
  axios.get(`${BaseUrl}/om/organizations`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const putOrganization = (id, data) =>
  axios.put(`${BaseUrl}/om/organizations/${id}`, data);
export const deleteOrganization = (id) =>
  axios.delete(`${BaseUrl}/om/organizations/${id}`);
export const getOrganizationEmployees = (request, id) =>
  axios.get(`${BaseUrl}/om/organizations/${id}/employees`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });

//------------------------------------------------------------------------------------------------------------------------------------

export const listEmployeementsByOrganization = (request, id) =>
  axios.get(`${BaseUrl}/om/employeements/organization/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listUnemployeed = (request) =>
  axios.get(`${BaseUrl}/om/employeements/unemployeed`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const getMyEmployeement = () =>
  axios.get(`${BaseUrl}/om/employeements/my-employeement`);
export const postEmployeement = (data) =>
  axios.post(`${BaseUrl}/om/employeements`, data);
export const putEmployeement = (id, data) =>
  axios.put(`${BaseUrl}/om/employeements/${id}`, data);
export const getEmployeement = (id) =>
  axios.get(`${BaseUrl}/om/employeements/${id}`);
export const deleteEmployeement = (id) =>
  axios.delete(`${BaseUrl}/om/employeements/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listOrganizationClientsByOrganization = (request, id) =>
  axios.get(`${BaseUrl}/om/organization-clients`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listUnassignedToOrganizations = (request) =>
  axios.get(`${BaseUrl}/om/organization-clients/unassigned`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listOrganizationClientsRecursiveClientsForOrganization = (
  request,
  id
) =>
  axios.get(
    `${BaseUrl}/om/organization-clients/recursively/organization/${id}`
  );
export const postOrganizationClient = (data) =>
  axios.post(`${BaseUrl}/om/organization-clients`, data);
export const getOrganizationClient = (id) =>
  axios.get(`${BaseUrl}/om/organization-clients/${id}`);
export const deleteOrganizationClient = (id) =>
  axios.delete(`${BaseUrl}/om/organization-clients/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listEmployeeClientsByEmployee = (request, id) =>
  axios.get(`${BaseUrl}/om/employee-clients/employee/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listAssignedOrganizationsForEmployeeRecursively = (request, id) =>
  axios.get(
    `${BaseUrl}/om/employee-clients/assigned-recursively/employee/${id}`,
    {
      params: request
        ? {
            ...request,
            ...request.filters,
          }
        : undefined,
    }
  );
export const listUnassignedToEmployees = (request, id) =>
  axios.get(`${BaseUrl}/om/employee-clients/unassigned/employee/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postEmployeeClient = (data) =>
  axios.post(`${BaseUrl}/om/employee-clients`, data);
export const getEmployeeClient = (id) =>
  axios.get(`${BaseUrl}/om/employee-clients/${id}`);
export const deleteEmployeeClient = (id) =>
  axios.delete(`${BaseUrl}/om/employee-clients/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listSupervisionsUnsupervisedForEmployee = (request, id) =>
  axios.get(`${BaseUrl}/om/supervisions/unsupervised/employee/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listSupervisionsForEmployee = (request, id) =>
  axios.get(`${BaseUrl}/om/supervisions/employee/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const getSupervision = (id) =>
  axios.get(`${BaseUrl}/om/supervisions/${id}`);
export const postSupervision = (data) =>
  axios.post(`${BaseUrl}/om/supervisions`, data);
export const deleteSupervision = (id) =>
  axios.delete(`${BaseUrl}/om/supervisions/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const getUserInfo = () => axios.get(`${BaseUrl}/um/users/userinfo`);
export const postLogout = () => axios.post(`${BaseUrl}/logout`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listRoutesForRoute = (request, id) =>
  axios.get(`${BaseUrl}/rm/routes/route/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postRoute = (id) => axios.post(`${BaseUrl}/rm/routes/${id}`);
export const putRoute = (id, data) =>
  axios.put(`${BaseUrl}/rm/routes/${id}`, data);
export const getRoute = (id) => axios.get(`${BaseUrl}/rm/routes/${id}`);
export const deleteRoute = (id) => axios.delete(`${BaseUrl}/rm/routes/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listActivatedRouteLocationsForActivatedRoute = (request, id) =>
  axios.get(`${BaseUrl}/rm/activated-route-locations/activated-route/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postActivatedRouteLocation = (data) =>
  axios.post(`${BaseUrl}/rm/activated-route-locations`, data);
export const putActivatedRouteLocation = (id, data) =>
  axios.put(`${BaseUrl}/rm/activated-route-locations/${id}`, data);
export const getActivatedRouteLocation = (id) =>
  axios.get(`${BaseUrl}/rm/activated-route-locations/${id}`);
export const deleteActivatedRouteLocation = (id) =>
  axios.delete(`${BaseUrl}/rm/activated-route-locations/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listOrganizationRouteForOrganization = (request, id) =>
  axios.get(`${BaseUrl}/orem/organization-routes/organization/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postOrganizationRoute = (data) =>
  axios.post(`${BaseUrl}/orem/organization-routes`, data);
export const getOrganizationRoute = (id) =>
  axios.get(`${BaseUrl}/orem/organization-routes/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const postEmployeeParentEvent = (data) =>
  axios.post(`${BaseUrl}/orem/employee-parent-events`, data);

//------------------------------------------------------------------------------------------------------------------------------------

export const listEmployeeEventForEmployeAndMonth = (request, id) =>
  axios.get(`${BaseUrl}/orem/employee-events/employee/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const getEmployeeEventForRoute = (id) =>
  axios.get(`${BaseUrl}/orem/employee-events/route/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listParentRouteLocationsForParentRoute = (request, id) =>
  axios.get(`${BaseUrl}/orem/parent-route-locations/parent-route/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postParentRouteLocation = (data) =>
  axios.post(`${BaseUrl}/rm/parent-route-locations`, data);
export const putParentRouteLocation = (id, data) =>
  axios.put(`${BaseUrl}/rm/parent-route-locations/${id}`, data);
export const deleteParentRouteLocation = (id, request) =>
  axios.delete(`${BaseUrl}/rm/parent-route-locations/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });

//------------------------------------------------------------------------------------------------------------------------------------

export const listRouteLocationsForRoute = (request, id) =>
  axios.get(`${BaseUrl}/orem/route-locations/route/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postRouteLocation = (data) =>
  axios.post(`${BaseUrl}/rm/route-locations`, data);
export const putRouteLocation = (id, data) =>
  axios.put(`${BaseUrl}/rm/route-locations/${id}`, data);
export const deleteRouteLocation = (id) =>
  axios.delete(`${BaseUrl}/rm/route-locations/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listProductCategories = (request) =>
  axios.get(`${BaseUrl}/im/product-categories`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postProductCategory = (data) =>
  axios.post(`${BaseUrl}/im/product-categories`, data);
export const putProductCategory = (id, data) =>
  axios.put(`${BaseUrl}/im/product-categories/${id}`, data);
export const deleteProductCategory = (id) =>
  axios.delete(`${BaseUrl}/im/product-categories/${id}`);
export const getProductCategory = (id) =>
  axios.get(`${BaseUrl}/im/product-categories/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listProductsForCategory = (request, id) =>
  axios.get(`${BaseUrl}/im/products/category/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });

export const postProduct = (data) => axios.post(`${BaseUrl}/im/products`, data);
export const putProduct = (id, data) =>
  axios.put(`${BaseUrl}/im/products/${id}`, data);
export const deleteProduct = (id) =>
  axios.delete(`${BaseUrl}/im/products/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listEmployeeProductCategoriesForEmployee = (request, id) =>
  axios.get(`${BaseUrl}/orem/employee-product-categories/employee/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postEmployeeProductCategory = (data) =>
  axios.post(`${BaseUrl}/orem/employee-product-categories`, data);
export const deleteEmployeeProductCategory = (id) =>
  axios.delete(`${BaseUrl}/orem/employee-product-categories/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const postEmployeeVisit = (data) =>
  axios.post(`${BaseUrl}/orem/visits`, data);
export const getEmployeeVisit = (id) =>
  axios.get(`${BaseUrl}/orem/visits/${id}`);
export const deleteEmployeeVisit = (id) =>
  axios.delete(`${BaseUrl}/orem/visits/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listVisitStocksForVisit = (request, id) =>
  axios.get(`${BaseUrl}/orem/visit-stocks/visit/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postVisitStock = (data) =>
  axios.post(`${BaseUrl}/orem/visit-stocks`, data);
export const putVisitStock = (id, data) =>
  axios.put(`${BaseUrl}/orem/visit-stocks/${id}`, data);

//------------------------------------------------------------------------------------------------------------------------------------

export const listStocksForOrganization = (request, id) =>
  axios.get(`${BaseUrl}/orem/stocks/organization/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });

//------------------------------------------------------------------------------------------------------------------------------------

export const postStockTransfer = (data) =>
  axios.post(`${BaseUrl}/orem/stock-transfers`, data);
export const getStockTransfer = (id) =>
  axios.get(`${BaseUrl}/orem/stock-transfers/${id}`);
export const putStockTransfer = (id, data) =>
  axios.put(`${BaseUrl}/orem/stock-transfers/${id}`, data);
export const deleteStockTransfer = (id) =>
  axios.delete(`${BaseUrl}/orem/stock-transfers/${id}`);
export const listStockTransfersFrom = (request, id) =>
  axios.get(`${BaseUrl}/orem/stock-transfers/from/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listStockTransfersTo = (request, id) =>
  axios.get(`${BaseUrl}/orem/stock-transfers/to/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });

//------------------------------------------------------------------------------------------------------------------------------------

export const postStockTransferItem = (data) =>
  axios.post(`${BaseUrl}/orem/stock-transfer-items`, data);
export const getStockTransferItem = (id) =>
  axios.get(`${BaseUrl}/orem/stock-transfer-items/${id}`);
export const putStockTransferItem = (id, data) =>
  axios.put(`${BaseUrl}/orem/stock-transfer-items/${id}`, data);
export const deleteStockTransferItem = (id) =>
  axios.delete(`${BaseUrl}/orem/stock-transfer-items/${id}`);
export const listStockTransferItemsForStockTransfer = (request, id) =>
  axios.get(`${BaseUrl}/orem/stock-transfer-items/stock-transfer/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });

//------------------------------------------------------------------------------------------------------------------------------------

export const listOrdersTo = (request, id) =>
  axios.get(`${BaseUrl}/orem/orders/to/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listOrdersFrom = (request, id) =>
  axios.get(`${BaseUrl}/orem/orders/from/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });

export const postOrder = (data) => axios.post(`${BaseUrl}/orem/orders`, data);
export const getOrder = (id) => axios.get(`${BaseUrl}/orem/orders/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listOrderItemForOrder = (request, id) =>
  axios.get(`${BaseUrl}/orem/order-items/order/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listForDelivery = (request, id) =>
  axios.get(`${BaseUrl}/orem/order-items/delivery/to/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postOrderItem = (data) =>
  axios.post(`${BaseUrl}/orem/order-items`, data);
export const putOrderItem = (id, data) =>
  axios.put(`${BaseUrl}/orem/order-items/${id}`, data);
export const deleteOrderItem = (id, data) =>
  axios.delete(`${BaseUrl}/orem/order-items/${id}`);

export const orderOrder = (id) =>
  axios.put(`${BaseUrl}/orem/orders/order/${id}`);
export const cancelOrder = (id) =>
  axios.put(`${BaseUrl}/orem/orders/cancel/${id}`);
export const acceptOrder = (id) =>
  axios.put(`${BaseUrl}/orem/orders/accept/${id}`);
export const rejectOrder = (id) =>
  axios.put(`${BaseUrl}/orem/orders/reject/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listDeliveriesForFromInventory = (request, id) =>
  axios.get(`${BaseUrl}/orem/deliveries/from/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const listDeliveriesForToInventory = (request, id) =>
  axios.get(`${BaseUrl}/orem/deliveries/to/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postDelivery = (data) =>
  axios.post(`${BaseUrl}/orem/deliveries`, data);
export const getDelivery = (id) =>
  axios.get(`${BaseUrl}/orem/deliveries/${id}`);

export const readyDelivery = (id) =>
  axios.put(`${BaseUrl}/orem/deliveries/ready/${id}`);
export const dispatchDelivery = (id) =>
  axios.put(`${BaseUrl}/orem/deliveries/dispatch/${id}`);
export const cancelDelivery = (id) =>
  axios.put(`${BaseUrl}/orem/deliveries/cancel/${id}`);
export const rejectDelivery = (id) =>
  axios.put(`${BaseUrl}/orem/deliveries/reject/${id}`);
export const deliverDelivery = (id) =>
  axios.put(`${BaseUrl}/orem/deliveries/deliver/${id}`);

//------------------------------------------------------------------------------------------------------------------------------------

export const listDeliveryItemsForDelivery = (request, id) =>
  axios.get(`${BaseUrl}/orem/delivery-items/delivery/${id}`, {
    params: request
      ? {
          ...request,
          ...request.filters,
        }
      : undefined,
  });
export const postDeliveryItem = (data) =>
  axios.post(`${BaseUrl}/orem/delivery-items`, data);
export const putDeliveryItem = (id, data) =>
  axios.put(`${BaseUrl}/orem/delivery-items/${id}`, data);
export const postBulkDeliveryItem = (data) =>
  axios.post(`${BaseUrl}/orem/delivery-items/bulk`, data);

const generateBaseApis = (microService, resourceName) => ({
  postApi: (data) =>
    axios.post(`${BaseUrl}/${microService}/${resourceName}`, data),
  postBulkApi: (data) =>
    axios.post(`${BaseUrl}/${microService}/${resourceName}/bulk`, data),
  getApi: (id) => axios.get(`${BaseUrl}/${microService}/${resourceName}/${id}`),
  getBulkApi: (ids) =>
    axios.get(`${BaseUrl}/${microService}/${resourceName}/bulk/${ids.join()}`),
  putApi: (id, data) =>
    axios.put(`${BaseUrl}/${microService}/${resourceName}/${id}`, data),
  putBulkApi: (ids, data) =>
    axios.put(
      `${BaseUrl}/${microService}/${resourceName}/bulk/${ids.join()}`,
      data
    ),
  deleteApi: (id) =>
    axios.delete(`${BaseUrl}/${microService}/${resourceName}/${id}`),
  deleteBulkApi: (ids) =>
    axios.delete(
      `${BaseUrl}/${microService}/${resourceName}/bulk/${ids.join()}`
    ),
  listApi: (request) =>
    axios.post(
      `${BaseUrl}/${microService}/${resourceName}/list`,
      request
        ? request
        : {
            pageable: {
              page: 0,
              size: 1000,
              sort: "lastModifiedDate",
              direction: "DESC",
            },
            entity: {},
          }
    ),
  listRevisions: (request) =>
    axios.get(
      `${BaseUrl}/${microService}/${resourceName}/${request.id}/revisions`,
      {
        params: {
          ...request.pageable,
        },
      }
    ),
  exportApi: (request) =>
    axios.post(
      `${BaseUrl}/${microService}/${resourceName}/export`,
      request
        ? request
        : {
            pageable: {
              page: 0,
              size: 1000,
              sort: "lastModifiedDate",
              direction: "DESC",
            },
            entity: {},
          }
    ),
}
);

export const titleApis = generateBaseApis("om", "titles");
export const organizationClientApi = generateBaseApis(
  "orem",
  "organization-clients"
);
export const organizationLevelApi = generateBaseApis(
  "om",
  "organization-levels"
);
export const employeeLevelApis = generateBaseApis("om", "employee-levels");
export const employeementApis = generateBaseApis("orem", "employeements");
export const employeeClientApis = generateBaseApis("orem", "employee-clients");
export const supervisionApis = generateBaseApis("om", "supervisions");

export const organizationRouteApis = generateBaseApis(
  "orem",
  "organization-routes"
);
export const parentRouteLocationApis = generateBaseApis(
  "orem",
  "parent-route-locations"
);
export const stockApis = generateBaseApis("orem", "stocks");
export const employeeProductCategoryApis = generateBaseApis(
  "orem",
  "employee-product-categories"
);
export const organizationApis = generateBaseApis("orem", "organizations");
export const employeeEventApis = generateBaseApis("orem", "employee-events");
export const employeeParentEventApis = generateBaseApis(
  "orem",
  "employee-parent-events"
);
export const orderApis = generateBaseApis("orem", "orders");
export const orderItemApis = generateBaseApis("orem", "order-items");
export const deliveryApis = generateBaseApis("orem", "deliveries");
export const deliveryItemApis = generateBaseApis("orem", "delivery-items");
export const productApis = {
  ...generateBaseApis("orem", "products"),
  listProductsForEmployee: (id) =>
    axios.get(`${BaseUrl}/orem/products/employee/${id}`),
};
export const inventoryTargetApis = {
  ...generateBaseApis("orem", "inventory-targets"),
  updateTargets: (request) =>
    axios.post(`${BaseUrl}/orem/inventory-targets/update`, request),
  getBetween: (request) =>
    axios.post(`${BaseUrl}/orem/inventory-targets/between`, request),
  getDistinct: (request, inventoryId) =>
    axios.get(`${BaseUrl}/orem/inventory-targets/distinct/${inventoryId}`),
  exportTargets: (request) =>
    axios.post(`${BaseUrl}/orem/inventory-targets/export-targets`, request),
};
export const employeeTargetApis = {
  ...generateBaseApis("orem", "employee-targets"),
  updateTargets: (request) =>
    axios.post(`${BaseUrl}/orem/employee-targets/update`, request),
  getBetween: (request) =>
    axios.post(`${BaseUrl}/orem/employee-targets/between`, request),
  getDistinct: (request, employeeId) =>
    axios.get(`${BaseUrl}/orem/employee-targets/distinct/${employeeId}`),
  exportTargets: (request) =>
    axios.post(`${BaseUrl}/orem/employee-targets/export-targets`, request),
};
export const inventoryApis = generateBaseApis("orem", "inventories");

export const productCategoryApis = generateBaseApis("im", "product-categories");
export const employeeVisitApis = generateBaseApis("orem", "employee-visits");
export const routeLocationApis = generateBaseApis("orem", "route-locations");

export const demoApis = generateBaseApis("demo3", "demos");
export const subDemoApis = generateBaseApis("demo3", "sub-demos");
export const visitStockApis = generateBaseApis("orem", "visit-stocks");
export const deviceApis = generateBaseApis("orem", "devices");
export const layoutApis = generateBaseApis(
  "om",
  "organization-analytics-layouts"
);
