import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, FunnelChart, Funnel, LabelList } from "recharts";
import { useCallback } from "react";
import { colors } from "./DataPieChart";

const RADIAN = Math.PI / 180;

const DataFunnelChart = ({ name, value, data, height, width }) => {

    return <ResponsiveContainer
        width={width || '100%'}
        height={height || '100%'}
    >
        <FunnelChart>
            <Tooltip />
            <Funnel
                dataKey={value}
                data={data.map((item, index) => ({
                    ...item,
                    fill: colors[index],
                }))}
                isAnimationActive
            >
                <LabelList position="right" fill="#000" stroke="none" dataKey={name} />
            </Funnel>
        </FunnelChart>
    </ResponsiveContainer>
}

export default DataFunnelChart