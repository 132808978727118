import Map from './Map'
import MainMenu from './MainMenu'
import Organization from '../Organization/Organization'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { Layout, Result, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { breakMainMenu, breakMap, unBreakMainMenu, unBreakMap } from '../app/windowStateReducer'
import OrganizationsList from '../Organization/OrganizationsList'
import OrganizationLevels from '../Settings/OrganizationLevels'
import EmployeeLevels from '../Settings/EmployeeLevels'
import EmployeeLevel from '../EmployeeLevel/EmployeeLevel'
import Employee from '../Employee/Employee'
import { getMyEmployeement } from '../api/api'
import { useEffect } from 'react'
import RoutePage from '../Route/Route';
import OrganizationRoute from '../OrganizationRoute/OrganizationRoute'
import Visit from '../Visit/Visit';
import ProductCategories from '../ProductCategories/ProductCategories';
import ProductCategory from '../ProductCategory/ProductCategory';
import Dashboard from './Dashboard';
import Order from '../Order/Order';
import ClientOrder from '../ClientOrder/ClientOrder';
import Delivery from '../Delivery/Delivery';
import InventoryTarget from "../InventoryTarget/InventoryTarget";
import EmployeeTarget from '../EmployeeTarget/EmployeeTarget';
import userManager from "../app/userManager";
// import axios from "axios";
import { setMyEmployment, setSuperUser } from "../app/profile";
import ErrorHandler from "../util/ErrorHandler";
import MainPage from "./MainPage";
import Test from "../Organization/Analytics";

const Page = () => {
    const { path } = useRouteMatch()
    const history = useHistory()
    const dispatch = useDispatch()
    const showMap = useSelector(state => state.windowState.showMap)

    const user = useSelector(state => state.profile.user)
    const profile = useSelector(state => state.profile)

    useEffect(() => {
        // if (!user) {
        //     userManager.signinRedirect()
        // } else {
        // if (user.profile.resource_access === undefined) {
        //     history.push('/forbidden')
        //     return
        // }

        // axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`
        // axios.interceptors.response.use(response => {
        //     return response;
        // }, error => {
        //     userManager.startSilentRenew();
        //     return Promise.reject(error)
        // })

        // if (user.profile.resource_access.sfm.roles.filter(item => item === 'super-user').length === 1) {
        //     dispatch(setSuperUser(true))
        // }

        getMyEmployeement().then(response => {
            dispatch(setMyEmployment(response.data));

        }).catch(error => {
            // if (user.profile.resource_access.sfm.roles.filter(item => item === 'super-user').length === 0) {
            //     ErrorHandler(error, 'Failed to load your employment !')
            // }
        })
        // }
    }, [user, history, dispatch])

    const mainMenuBroken = (broken) => {
        if (broken) {
            dispatch(breakMainMenu())
        } else {
            dispatch(unBreakMainMenu())
        }
    }

    const mapBroken = (broken) => {
        if (broken) {
            dispatch(breakMap())
        } else {
            dispatch(unBreakMap())
        }
    }

    return !user ? <Skeleton title={true} paragraph={true} avatar={true} /> : <Layout>
        <Layout.Sider
            breakpoint={'md'}
            onBreakpoint={mainMenuBroken}
            collapsedWidth={0}
            theme={'light'}
            trigger={null}
            style={{
                margin: '10px 0 10px 0',
                borderRadius: '0 10px 10px 0',
            }}
        >
            <MainMenu />
        </Layout.Sider>
        <Layout.Content
            style={{
                padding: 10,
                height: '100vh',
                overflow: 'auto'
            }}
        >
            <Switch>
                <Route exact path={path}>
                    {profile.myEmployment ? <Redirect to={`/employee/${profile.myEmployment.id}`} /> : <Result
                        title="You are not registered as an employee."
                        subTitle="Please contact your supervisor !"
                    />}
                </Route>
                <Route exact path={`${path}organization`}>
                    <OrganizationsList />
                </Route>
                <Route path={`${path}organization/:id`}>
                    <Organization />
                </Route>
                <Route path={`${path}employee/:id`}>
                    <Employee />
                </Route>
                <Route path={`${path}settings/organization-levels`}>
                    <OrganizationLevels />
                </Route>
                <Route path={`${path}settings/employee-levels`}>
                    <EmployeeLevels />
                </Route>
                <Route path={`${path}settings/product-categories`}>
                    <ProductCategories />
                </Route>
                <Route path={`${path}employee-level/:id`}>
                    <EmployeeLevel />
                </Route>
                <Route path={`${path}route/:id`}>
                    <RoutePage />
                </Route>
                <Route path={`${path}organization-route/:id`}>
                    <OrganizationRoute />
                </Route>
                <Route path={`${path}visits/:id`}>
                    <Visit />
                </Route>
                <Route path={`${path}product-category/:id`}>
                    <ProductCategory />
                </Route>
                <Route path={`${path}orders/:id`}>
                    <Order />
                </Route>
                <Route path={`${path}client-orders/:id`}>
                    <ClientOrder />
                </Route>
                <Route path={`${path}deliveries/:id`}>
                    <Delivery />
                </Route>
                <Route path={`${path}inventory-targets/inventory/:inventoryId/product/:productId`}>
                    <InventoryTarget />
                </Route>
                <Route path={`${path}employee-targets/employee/:employeeId/product/:productId`}>
                    <EmployeeTarget />
                </Route>
                <Route path={`${path}test2`}>
                    <Test />
                </Route>
                <Route path={`${path}forbidden`}>
                    <MainPage>
                        <Result
                            status={403}
                            title={'You are not allowed to access the system !'}
                            subTitle={'Please contact your administrator for more details.'}
                        />
                    </MainPage>
                </Route>
            </Switch>
        </Layout.Content>
        {showMap && <Layout.Sider
            breakpoint={'xl'}
            collapsedWidth={0}
            reverseArrow={true}
            width={500}
            onBreakpoint={mapBroken}
            theme={'light'}
            trigger={null}
            style={{
                margin: '10px 0 10px 0',
                borderRadius: '10px 0 0 10px',
                overflow: 'hidden',
            }}
        >
            <Map
                mapContainerStyle={{
                    height: 'calc(100vh - 20px)',
                    width: '100%',
                }}
            />
        </Layout.Sider>}
    </Layout>
}

export default Page