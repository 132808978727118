import {organizationRouteApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import {useHistory} from 'react-router-dom'
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setShowMap} from "../app/windowStateReducer";

const Routes = ({parent, visible}) => {

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    return <DataTable
        deletable={record => true}
        editable={true}
        defaultFilters={{
            organizationId: parent.id,
        }}
        apis={organizationRouteApis}
        browse={(record) => history.push(`/organization-route/${record.id}`)}
        itemName={'parent_route'}
        parent={parent}
        columns={[
            {
                title: 'Name',
                dataIndex: ['parentRoute', 'name'],
                browse: true,
                editable: record => true,
                type: 'text',
            }
        ]}
    />
}

export default Routes