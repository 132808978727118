import { Area, AreaChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine } from "recharts";
import { PureComponent } from "react";
import { colors } from "./DataPieChart";

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
        );
    }
}

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-25)">
                    {payload.value}
                </text>
            </g>
        );
    }
}

const DataAreaChart = ({ xAxisLabel, yAxisLabel, xAxisDataIndex, data, structure, stacked, xRefLines, yRefLines }) => {

    return <ResponsiveContainer width={'100%'} height={'100%'}>
        <AreaChart data={data}>
            <defs>
                {
                    colors.map((color, index) => <linearGradient id={`${index}-color`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={color} stopOpacity={0.2} />
                    </linearGradient>)
                }
            </defs>
            <Legend verticalAlign="bottom" align="center" height={36} iconType={'square'} layout="horizontal" wrapperStyle={{ bottom: 0 }} />
            <XAxis axisLine={false} tickLine={false} height={50} dataKey={xAxisDataIndex} tick={<CustomizedAxisTick />} />
            <YAxis axisLine={false} tickLine={false} width={70} />
            {xRefLines &&
                xRefLines.map((item) => {
                    return (
                        <ReferenceLine
                            x={item.x}
                            stroke={item.stroke}
                            label={item.label}
                        />
                    )
                })}
            {yRefLines && yRefLines.map((item) => {
                return (
                    <ReferenceLine
                        y={item.y}
                        label={item.label}
                        stroke={item.stroke}
                    />
                )
            })}

            <Tooltip />
            {
                structure.items.map((item, i) => {
                    return <Area connectNulls={false} stackId={stacked ? 1 : undefined} type="monotone"
                        dataKey={item.dataIndex} stroke={item.color ? item.color : colors[i]}
                        fillOpacity={item.opacity} fill={item.color ? item.color : `url(#${i}-color)`} />
                })
            }
        </AreaChart>
    </ResponsiveContainer>
}

export default DataAreaChart