import { Select, Spin } from 'antd';
import {
  listUnassignedToOrganizations,
  organizationApis,
  organizationClientApi,
} from '../api/api';
import DataTable from '../core/DataTable/DataTable';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addMarkers } from '../app/markerReducer';
import useListLoader from '../util/useListLoader';
import { useEffect } from 'react';
import { setShowMap } from '../app/windowStateReducer';

const Clients = ({ parent, visible }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) dispatch(setShowMap(true));
  }, [visible]);

  const addToMap = (data) => {
    let locations = data.content.map((client) => ({
      ...client.child.location,
      name: client.child.name,
      icon: client.child.level.id,
    }));
    dispatch(addMarkers(locations));
  };

  return (
    <Spin spinning={false}>
      <DataTable
        editable={true}
        deletable={(record) => true}
        defaultFilters={{
          parent: {
            active: true,
            id: parent.id,
          },
        }}
        apis={organizationClientApi}
        dataProcessor={addToMap}
        browse={(record) => history.push(`/organization/${record.child.id}`)}
        itemName={'organization_client'}
        parent={parent}
        columns={[
          {
            title: 'Name',
            dataIndex: ['child', 'id'],
            persistedDataIndex: ['child', 'name'],
            browse: true,
            type: 'select',
            editable: (record) => record.temp,
            fetch: (search) =>
              organizationApis.listApi({
                pageable: {
                  page: 0,
                  size: 10,
                  sort: 'lastModifiedDate',
                  direction: 'DESC',
                },
                entity: {
                  name: search,
                },
              }),
            fetchToItems: (response) =>
              response.data.content.map((item) => (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              )),
          },
          {
            title: 'Level',
            dataIndex: ['child', 'level', 'name'],
          },
          {
            title: 'Email',
            dataIndex: ['child', 'contact', 'email'],
            render: (text, record, index) => text || 'Not Available',
          },
        ]}
      />
    </Spin>
  );
};

export default Clients;
