import { notification } from 'antd';

const ErrorHandler = (error, message) => {
  console.log(error, message);
  if (
    error.response &&
    (error.response.status === 401 ||
      error.response.status === 403 ||
      error.response.status === 400)
  ) {
    notification['error']({
      message: message,
      description: error
        ? error.response
          ? error.response.data
            ? error.response.data.message
              ? error.response.data.message
              : error.response.data.toString()
            : error.response.toString()
          : error.toString()
        : 'You are not allowed to perform this action !',
    });
    return;
  }
  notification['error']({
    message: message,
    description: error
      ? error.response
        ? error.response.data
          ? error.response.data.message
            ? error.response.data.message
            : error.response.data.toString()
          : error.response.toString()
        : error.toString()
      : '',
  });
};

export default ErrorHandler;
