import {Select, Spin} from "antd"
import {employeeProductCategoryApis, orderItemApis, productApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import ErrorHandler from "../util/ErrorHandler"
import useListLoader from "../util/useListLoader";

const OrderItems = ({parent}) => {
    const employeement = useSelector(state => state.profile.myEmployment)

    const [loadingEmployeeProductCategories, employeeProductCategories] = useListLoader(employeeProductCategoryApis.listApi, {
        entity: {
            employeementId: employeement && employeement.id,
        },
        pageable: {
            page: 0,
            size: 1000,
            sort: 'lastModifiedDate',
            direction: 'DESC'
        }
    }, [employeement])

    const [products, setProducts] = useState();
    const [loadingProducts, setLoadingProducts] = useState(false)

    useEffect(() => {
        if (employeeProductCategories)
            fetchProducts()
    }, [employeeProductCategories])

    const fetchProducts = () => {
        let products = []

        if(employeeProductCategories.content.length === 0) {
            return;
        }
        setLoadingProducts(true)
        employeeProductCategories.content.map((category, index) => productApis.listApi({
            entity: {
                category: {
                    id: category.productCategory.id,
                },
            },
            pageable: {
                page: 0,
                size: 1000,
                sort: 'lastModifiedDate',
                direction: 'DESC'
            },
        }).then(response => {
            products.push(...response.data.content)
            setProducts({
                content: products
            })
            if (index === employeeProductCategories.content.length - 1) {
                setLoadingProducts(false)
            }
        }).catch(error => {
            ErrorHandler(error, 'Failed to get products !')
        }))

    }

    return <Spin spinning={loadingEmployeeProductCategories || loadingProducts}>
        {!loadingEmployeeProductCategories && !loadingProducts && <DataTable
            itemName={'order_item'}
            editable={false}
            defaultFilters={{
                order: parent,
            }}
            apis={orderItemApis}
            parent={parent}
            columns={[
                {
                    title: 'Product',
                    dataIndex: ['product', 'id'],
                    persistedDataIndex: ['product', 'name'],
                    type: 'select',
                    items: products && products.content.map(item => <Select value={item.id}>{item.name}</Select>),
                    editable: record => parent.orderStatus === 'DRAFT',
                },
                {
                    title: 'Ordered',
                    dataIndex: ['orderedQuantity'],
                    type: 'number',
                    width: '10%',
                    align: 'right',
                    editable: record => parent.orderStatus === 'DRAFT',
                },
                {
                    title: 'Dispatched',
                    dataIndex: ['dispatchedQuantity'],
                    type: 'number',
                    align: 'right',
                    width: '10%',
                },
                {
                    title: 'Delivered',
                    dataIndex: ['deliveredQuantity'],
                    type: 'number',
                    align: 'right',
                    width: '10%',
                },
            ]}
            deletable={record => record.order.orderStatus === 'DRAFT'}
        />}
    </Spin>
}

export default OrderItems