const PathHandler = (location, level, defaultRoute) => {
    const segments = location.pathname.split('/')
    const important = segments[segments.length - 1]

    if (segments.length === level) {
        return important;
    } else {
        return defaultRoute
    }
}

export default PathHandler