import {createSlice} from '@reduxjs/toolkit'

export const windowStateReducer = createSlice({
    name: 'windowState',
    initialState: {
        mainMenuBroken: false,
        mapBroken: false,
        showMap: true,
    },
    reducers: {
        breakMainMenu: (state, action) => {
            state.mainMenuBroken = true
        },
        unBreakMainMenu: (state, action) => {
            state.mainMenuBroken = false
        },
        breakMap: (state, action) => {
            state.mapBroken = true
        },
        unBreakMap: (state, action) => {
            state.mapBroken = false
        },
        setShowMap: (state, action) => {
            state.showMap = action.payload
        }
    },
})

export const {breakMainMenu, unBreakMainMenu, breakMap, unBreakMap, setShowMap} = windowStateReducer.actions

export default windowStateReducer.reducer