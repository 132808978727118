import {employeeProductCategoryApis, productCategoryApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import {useEffect, useState} from "react";
import ErrorHandler from "../util/ErrorHandler";
import {Select} from "antd";
import {useHistory} from "react-router-dom";
import {setShowMap} from "../app/windowStateReducer";
import {useDispatch} from "react-redux";

const ProductCategories = ({parent, visible}) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const [LoadingItems, setLoadingItems] = useState(false)
    const [Items, setItems] = useState({
        content: [],
    })

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    useEffect(() => {
        fetchProductCategories()
    }, [parent])

    const fetchProductCategories = () => {
        setLoadingItems(true)
        productCategoryApis.listApi().then(response => {
            setItems(response.data)
            setLoadingItems(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get product categories !')
            setLoadingItems(false)
        })
    }

    return <DataTable
        itemName={'product_category'}
        deletable={record => true}
        editable={true}
        apis={employeeProductCategoryApis}
        defaultFilters={{
            employeementId: parent.id,
        }}
        browse={record => history.push(`/product-category/${record.productCategory.id}`)}
        columns={[
            {
                title: 'Name',
                dataIndex: ['productCategoryId'],
                persistedDataIndex: ['productCategory', 'name'],
                type: 'select',
                editable: record => record.temp,
                items: Items && Items.content && Items.content.map(item => <Select.Option
                    value={item.id}>{item.name}</Select.Option>)
            },
        ]}
    />
}

export default ProductCategories