import DataTable from "../core/DataTable/DataTable"
import {listSupervisionsUnsupervisedForEmployee, supervisionApis} from '../api/api'
import {Col, Form, Input, Row, Select, Spin} from 'antd'
import {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom"
import {setShowMap} from "../app/windowStateReducer";
import {useDispatch} from "react-redux";
import ErrorHandler from "../util/ErrorHandler";

const Supervisees = ({parent, visible}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [LoadingItems, setLoadingItems] = useState(false)
    const [Items, setItems] = useState({
        content: [],
    })

    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    useEffect(() => {
        fetchUnsupervisedEmployees()
    }, [parent])

    const fetchUnsupervisedEmployees = () => {
        setLoadingItems(true)
        listSupervisionsUnsupervisedForEmployee({
            page: 0,
            size: 1000,
            sortBy: 'lastModifiedDate',
            sortDirection: 'descend',
        }, parent.id).then(response => {
            setItems(response.data)
            setLoadingItems(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get unsupervised users !')
            setLoadingItems(false)
        })
    }

    const formRender = old => <>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={'Supervisor'}
                    name={old ? ['supervisor', 'user', 'firstName'] : ['supervisor', 'id']}
                    rules={[
                        {
                            required: true,
                            message: 'Supervisor is required !'
                        }
                    ]}
                >
                    <Input disabled={old}/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col xs={24}>
                <Form.Item
                    label={'Supervisee'}
                    name={['supervisee', 'id']}
                    rules={[
                        {
                            required: true,
                            message: 'Supervisor is required !',
                        }
                    ]}
                >
                    <Select
                        disabled={old}
                        showSearch={true}
                        style={{width: '100%'}}
                        placeholder={'Select an unsupervised employee'}
                        optionFilterProp={'children'}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {
                            Items && Items.content.map(item => <Select.Option
                                value={item.id}>{item.user.firstName + ' ' + item.user.lastName}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    </>

    return <Spin spinning={LoadingItems}>
        <DataTable
            defaultFilters={{
                supervisor: {
                    id: parent.id,
                },
                active: true,
            }}
            apis={supervisionApis}
            editable={true}
            browse={(record) => history.push(`/employee/${record.supervisee.id}`)}
            itemName={'supervision'}
            parent={parent}
            deletable={record => true}
            columns={[
                {
                    title: 'Name',
                    dataIndex: ['supervisee', 'id'],
                    persistedDataIndex: ['supervisee', 'user', 'firstName'],
                    browse: true,
                    editable: record => record.temp,
                    type: 'select',
                    items: Items && Items.content && Items.content.map(item => <Select.Option
                        value={item.id}>{item.user.firstName}</Select.Option>)
                },
                {
                    title: 'Title',
                    dataIndex: ['supervisee', 'title', 'name'],
                },
            ]}
        />
    </Spin>
}

export default Supervisees