import {deliveryItemApis} from "../api/api"
import DataTable from "../core/DataTable/DataTable"
import DeliveryItemDrawerFormFunction from "./DeliveryItemDrawerFormFunction"
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setShowMap} from "../app/windowStateReducer";

const DeliveryItems = ({parent, visible}) => {

    const dispatch = useDispatch()
    useEffect(() => {
        if (visible)
            dispatch(setShowMap(false))
    }, [visible])

    return <DataTable
        itemName={'delivery_item'}
        deletable={record => true}
        editable={true}
        defaultFilters={{
            delivery: parent,
        }}
        apis={{
            ...deliveryItemApis,
            // listApi: listDeliveryItemsForDelivery,
            postApi: deliveryItemApis.postBulkApi,
        }}
        parent={parent}
        formToRestMapper={(data, record, op) => {
            if (record && !record.temp) {
                return ({
                    ...data,
                })
            }

            if (data.selected) {
                return ([
                    ...data.selected.map(item => ({
                        delivery: {
                            id: parent.id,
                        },
                        orderItem: {
                            id: item.id,
                        }
                    })),
                ])
            }

            return ([
                {
                    delivery: {
                        id: parent.id,
                    },
                    product: {
                        id: data.custom.product.id,
                    },
                    quantity: data.custom.quantity,
                }
            ])
        }}
        drawerForm={{
            render: DeliveryItemDrawerFormFunction
        }}
        columns={[
            {
                title: 'Product',
                dataIndex: ['product', 'name'],
            },
            {
                title: 'Demand',
                render: (text, record, index) => record.orderItem ? record.orderItem.orderedQuantity - record.orderItem.dispatchedQuantity : 0,
                type: 'number',
            },
            {
                title: 'Available',
                dataIndex: ['orderItem', 'product', 'availableQuantity'],
                type: 'number',
            },
            {
                title: 'Reserved',
                dataIndex: ['orderItem', 'product', 'reservedQuantity'],
                type: 'number',
            },
            {
                title: 'Quantity',
                dataIndex: ['quantity'],
                type: 'number',
                editable: record => parent.status === 'DRAFT',
            },
        ]}
    />
}

export default DeliveryItems