import MainPage from '../core/MainPage'
import DataTable from '../core/DataTable/DataTable'
import {productCategoryApis} from '../api/api'
import {useHistory} from 'react-router-dom'
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setShowMap} from "../app/windowStateReducer";

const ProductCategories = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setShowMap(false))
    }, [])

    return (
        <MainPage
            headerProps={{
                title: "Product Categories",
            }}
        >
            <DataTable
                apis={productCategoryApis}
                editable={true}
                deletable={record => true}
                itemName={'product_category'}
                browse={record => history.push(`/product-category/${record.id}`)}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: ['name'],
                        browse: true,
                        type: 'text',
                        editable: record => true,
                    }
                ]}
            />
        </MainPage>
    );
}

export default ProductCategories