import {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {getOrganizationRoute, organizationRouteApis} from '../api/api'
import MainPage from '../core/MainPage'
import ErrorHandler from '../util/ErrorHandler'
import {Button, Descriptions, Spin} from 'antd'
import Locations from './Locations'
import ItemDrawer from '../core/ItemDrawer'
import OrganizationRouteFormFunction from './OrganizationRouteFormFunction'
import {AreaChartOutlined, MoreOutlined} from "@ant-design/icons"
import {useDispatch} from "react-redux";
import {setShowMap} from "../app/windowStateReducer";

const OrganizationRoute = () => {
    const {id} = useParams()
    const history = useHistory()

    const [LoadingItem, setLoadingItem] = useState(false)
    const [Item, setItem] = useState(undefined)

    const [ShowDrawer, setShowDrawer] = useState(false);

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setShowMap(true))
    }, [])

    useEffect(() => {
        fetchItem()
    }, [id])

    const fetchItem = () => {
        setLoadingItem(true)
        getOrganizationRoute(id).then(response => {
            setItem(response.data)
            setLoadingItem(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get route !')
            setLoadingItem(false)
        })
    }

    return (
        <MainPage
            headerProps={{
                title: Item ? Item.parentRoute.name : "Loading ...",
                extra: [
                    <Button icon={<AreaChartOutlined/>}>{"REPORT"}</Button>,
                    <Button icon={<MoreOutlined/>} onClick={() => setShowDrawer(true)}>
                        {"MORE"}
                    </Button>,
                ],
            }}
            headerContent={Item && <Descriptions
                size={'small'}
                column={{xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1}}
            >
                <Descriptions.Item
                    label={'Organization'}>
                    <Button
                        type={"link"}
                        size={"small"}
                        onClick={() =>
                            history.push(`/organization/${Item.organization.id}`)
                        }
                    >
                        {Item.organization.name}
                    </Button>
                </Descriptions.Item>
            </Descriptions>

            }
            item={Item}
        >
            {Item && (
                <ItemDrawer
                    old={true}
                    item={Item}
                    visible={ShowDrawer}
                    apis={organizationRouteApis}
                    drawerFormUpdatable={true}
                    initialValues={Item}
                    onClose={() => {
                        setShowDrawer(false)
                    }}
                >
                    {OrganizationRouteFormFunction(true, Item)}
                </ItemDrawer>
            )}
            <Spin spinning={LoadingItem}>
                {Item && <Locations parent={Item}/>}
            </Spin>
        </MainPage>
    );
}

export default OrganizationRoute