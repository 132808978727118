import {Button, Drawer, Layout, Modal, PageHeader} from "antd"
import {DatabaseOutlined, GlobalOutlined, MenuOutlined} from '@ant-design/icons'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import Map from "./Map"
import MainMenu from "./MainMenu"
import {useHistory} from "react-router-dom"
import {JsonToTable} from "react-json-to-table";

const MainButtons = (windowState, setShowMapDrawer, setShowMainMenuDrawer, setShowFullData) => [windowState.mainMenuBroken &&
<Button onClick={() => setShowMainMenuDrawer(true)} icon={<MenuOutlined/>}/>, windowState.mapBroken &&
<Button onClick={() => setShowMapDrawer(true)} icon={<GlobalOutlined/>}/>,
    <Button onClick={() => setShowFullData(true)} icon={<DatabaseOutlined/>}/>]

const MainPage = ({item, headerContent, children, headerProps}) => {
    const history = useHistory();

    const windowState = useSelector((state) => state.windowState);

    const [ShowMapDrawer, setShowMapDrawer] = useState(false);
    const [ShowMainMenuDrawer, setShowMainMenuDrawer] = useState(false);

    const [showFullData, setShowFullData] = useState(false);

    useEffect(() => {
        if (!windowState.mainMenuBroken) {
            setShowMainMenuDrawer(false)
        }
    }, [windowState])

    return (
        <>
            <div
                style={{
                    overflowY: "auto",
                    borderRadius: 10,
                }}
            >
                {/* <Drawer
                    onClose={() => setShowMapDrawer(false)}
                    visible={ShowMapDrawer}
                    width={
                        windowState.mapBroken
                            ? windowState.mainMenuBroken
                                ? "100%"
                                : 500

                            : undefined
                    }
                    bodyStyle={{
                        padding: 0,
                    }}
                    contentWrapperStyle={{
                        overflow: "hidden",
                        padding: 0,
                    }}
                >
                    <Map
                        mapContainerStyle={{
                            width: "100%",
                            height: "100vh",
                        }}
                    />
                </Drawer>
                <Drawer
                    onClose={() => setShowMainMenuDrawer(false)}
                    visible={ShowMainMenuDrawer}
                    width={
                        windowState.mapBroken
                            ? windowState.mainMenuBroken
                                ? "100%"
                                : 500

                            : undefined
                    }
                >
                    <MainMenu/>
                </Drawer> */}
                {item && <Modal
                    title={'Full Data View'}
                    width={'95%'}
                    visible={showFullData}
                    onOk={() => setShowFullData(false)}
                    onCancel={() => setShowFullData(false)}
                    bodyStyle={{
                        overflow: 'auto'
                    }}
                >
                    <JsonToTable json={item}/>
                </Modal>}
                <Drawer
                    onClose={() => setShowMapDrawer(false)}
                    visible={ShowMapDrawer}
                    width={windowState.mapBroken ? windowState.mainMenuBroken ? '100%' : 500 : undefined}
                    bodyStyle={{
                        padding: 0,
                    }}
                    contentWrapperStyle={{
                        overflow: 'hidden',
                        padding: 0,
                    }}
                >
                    <Map
                        mapContainerStyle={{
                            width: '100%',
                            height: '100vh',
                        }}
                    />
                </Drawer>
                <Drawer
                    onClose={() => setShowMainMenuDrawer(false)}
                    visible={ShowMainMenuDrawer}
                    width={windowState.mapBroken ? windowState.mainMenuBroken ? '100%' : 500 : undefined}
                >
                    <MainMenu/>
                </Drawer>
                {
                    headerProps && <PageHeader
                        onBack={() => history.goBack()}
                        ghost={false}
                        {...headerProps}
                        extra={headerProps.extra ? [...headerProps.extra].concat(MainButtons(windowState, setShowMapDrawer, setShowMainMenuDrawer, setShowFullData)) : MainButtons(windowState, setShowMapDrawer, setShowMainMenuDrawer, setShowFullData)}
                    >
                        {
                            headerContent
                        }
                    </PageHeader>
                }
                <Layout.Content className={'site-layout-content'}>
                    {
                        children
                    }
                </Layout.Content>
            </div>
        </>
    );
}
export default MainPage;
