import { createSlice } from '@reduxjs/toolkit'

export const profile = createSlice({
    name: 'myEmployment',
    initialState: {
        myEmployment: null,
        superUser: false,
    },
    reducers: {
        setMyEmployment: (state, action) => {
            state.myEmployment = action.payload
        },
        setSuperUser: (state, action) => {
            state.superUser = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
            // state
        }
    },
    // extraReducers: builder => builder.addCase(USER_FOUND, (state, action) => {
    //     axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.access_token}`
    //     return state
    // })
})

export const { setMyEmployment, setSuperUser, setUser } = profile.actions

export default profile.reducer