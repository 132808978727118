import MainPage from "../core/MainPage"
import {Spin} from 'antd'
import {useParams} from "react-router-dom"
import {getEmployeeVisit} from "../api/api"
import {useEffect, useState} from "react"
import ErrorHandler from "../util/ErrorHandler"
import Stocks from "./Stocks"
import TabSet from '../core/TabSet'
import Orders from '../Organization/Orders'
import Sent from '../Organization/Sent'
import Received from "../Organization/Received"
import ClientOrders from "../Organization/ClientOrders"
import PageDescriptions from "../core/PageDescriptions"
import moment from "moment";

const Visit = () => {
    const {id} = useParams()

    const [LoadingItem, setLoadingItem] = useState()
    const [Item, setItem] = useState()

    useEffect(() => {
        fetchVisit()
    }, [id])

    const fetchVisit = () => {
        setLoadingItem(true)
        getEmployeeVisit(id).then(response => {
            setItem(response.data)
            setLoadingItem(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get visit !')
            setLoadingItem(false)
        })
    }

    return (
        <MainPage
            headerProps={{
                title: Item
                    ? "Visit to " + Item.routeLocation.organization.name
                    : "Loading ...",
            }}
            headerContent={Item && <PageDescriptions
                items={[
                    {
                        label: 'Organization',
                        value: Item.routeLocation.organization.name,
                        url: `/organization/${Item.routeLocation.organization.id}`
                    },
                    {
                        label: 'Time',
                        value: moment(Item.dateTime).format('dddd, MMMM Do YYYY, h:mm a')
                    }
                ]}
            />}
            item={Item}
        >
            <Spin
                spinning={LoadingItem}
                style={{
                    width: "100%",
                    minHeight: 400,
                }}
            >
                {Item && <TabSet
                    routes={{
                        default: 'stocks',
                        routes: [
                            Item.routeLocation.organization.parent.length > 0 && {
                                permission: 'stock_transfer:list',
                                name: 'Orders',
                                dataIndex: 'orders',
                                component: visible => <Orders visible={visible} parent={Item.routeLocation.organization}
                                                              updatable={true}/>
                            },
                            {
                                permission: 'delivery:list',
                                name: 'Received',
                                dataIndex: 'received',
                                component: visible => <Received visible={visible}
                                                                parent={Item.routeLocation.organization}/>
                            },
                            {
                                permission: 'stock_transfer:list',
                                name: 'Client Orders',
                                dataIndex: 'client-orders',
                                component: visible => <ClientOrders visible={visible}
                                                                    parent={Item.routeLocation.organization}
                                                                    updatable={true}/>
                            },
                            {
                                permission: 'stock_transfer:list',
                                name: 'Sent',
                                dataIndex: 'deliveries',
                                component: visible => <Sent visible={visible} parent={Item.routeLocation.organization}/>
                            },
                            {
                                permission: 'visit_stock:list',
                                name: 'Stocks',
                                dataIndex: 'stocks',
                                component: visible => <Stocks visible={visible} visit={Item}/>
                            },
                        ],
                    }}
                />}
            </Spin>
        </MainPage>
    );
}

export default Visit