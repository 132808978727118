import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { cancelOrder, getOrder, orderOrder, postDelivery } from '../api/api'
import MainPage from '../core/MainPage'
import TabSet from '../core/TabSet'
import ErrorHandler from '../util/ErrorHandler'
import { Button, Steps } from 'antd'
import moment from 'moment'
import PageDescriptions from '../core/PageDescriptions'
import OrderItems from './OrderItems'
import { useDispatch } from "react-redux";
import { setShowMap } from "../app/windowStateReducer";

const statuses = ['DRAFT',
    'ORDERED',
    'ACCEPTED',
    'REJECTED',
    'CANCELLED',]

const Order = () => {
    const { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()

    const [LoadingItem, setLoadingItem] = useState(false)
    const [Item, setItem] = useState()

    const [Status, setStatus] = useState()


    useEffect(() => {
        dispatch(setShowMap(false))
    }, [])

    useEffect(() => {
        if (!Item) {
            return
        }
        setStatus(statuses.findIndex(status => status === Item.stockTransferStatus))
    }, [Item])

    useEffect(() => {
        fetchItem()
    }, [id])

    const fetchItem = () => {
        setLoadingItem(true)
        getOrder(id).then(response => {
            setItem(response.data)
            setLoadingItem(false)
        }).catch(error => {
            ErrorHandler(error, 'Failed to get order item !', history)
            setLoadingItem(false)
        })
    }

    const buildExtras = () => {
        var extras = [];

        if (Item.orderStatus === 'DRAFT') {
            extras.push(<Button onClick={() => {
                orderOrder(Item.id).then(response => {
                    fetchItem()
                })
            }}>ORDER</Button>)
        } else if (Item.orderStatus === 'ORDERED') {
            extras.push(<Button onClick={() => {
                cancelOrder(Item.id)
                fetchItem()
            }}>CANCEL</Button>)
        } else if (Item.orderStatus === 'ACCEPTED' && Item.dispatchStatus !== 'DISPATCHED' && Item.toInventory.selfReceive) {
            extras.push(<Button onClick={() => {
                postDelivery({
                    fromInventory: Item.fromInventory,
                    toInventory: Item.toInventory,
                }).then(response => {
                    history.push(`/deliveries/${response.data.id}`)
                })
            }}>RECEIVE</Button>)
        }
        return extras
    }

    return (
        <MainPage
            headerProps={{
                title: Item
                    ? Item.fromOrganization.name + " to " + Item.toOrganization.name
                    : "Loading ...",
                extra: Item && buildExtras(),
            }}
            headerContent={
                Item && (
                    <PageDescriptions
                        items={[
                            {
                                label: "From",
                                value: Item.fromOrganization.name,
                                url: `/organization/${Item.fromOrganization.id}`,
                            },
                            {
                                label: "To",
                                value: Item.toOrganization.name,
                                url: `/organization/${Item.toOrganization.id}`,
                            },
                            {
                                label: "Status",
                                value: Item.orderStatus,
                            },
                            {
                                label: "Dispatch Status",
                                value: Item.dispatchStatus,
                            },
                            {
                                label: "Delivery Status",
                                value: Item.deliveryStatus,
                            },
                            // {
                            //     label: 'Created By',
                            //     value: Item.createdByUser.firstName + ' ' + Item.createdByUser.lastName,
                            // },
                        ]}
                    />)}
            item={Item}
        >
            {Item && Item.orderStatus !== 'ACCEPTED' && <Steps size={'small'} progressDot={true} current={Status}>
                <Steps.Step title={'Drafted'} description={moment(Item.createdDate).format('MMMM Do YYYY')} />
                <Steps.Step title={'Ordered'} description={moment(Item.orderedInstant).format('MMMM Do YYYY')} />
                {Item.orderStatus === 'ACCEPTED' &&
                    <Steps.Step title={'Accepted'} description={moment(Item.acceptedInstant).format('MMMM Do YYYY')} />}
                {Item.orderStatus === 'REJECTED' &&
                    <Steps.Step title={'Rejected'} description={moment(Item.rejectedInstant).format('MMMM Do YYYY')} />}
                {Item.orderStatus === 'CANCELLED' &&
                    <Steps.Step title={'Cancelled'} description={moment(Item.cancelledInstant).format('MMMM Do YYYY')} />}
            </Steps>}

            {Item && Item.orderStatus === 'ACCEPTED' && <Steps size={'small'} progressDot={true} current={Status}>
                <Steps.Step title={'Pending'} description={moment(Item.acceptedInstant).format('MMMM Do YYYY')} />
                <Steps.Step title={'Partially'}
                    description={moment(Item.partiallyDispatchedInstant).format('MMMM Do YYYY')} />
                <Steps.Step title={'Dispatched'} description={moment(Item.dispatchedInstant).format('MMMM Do YYYY')} />
            </Steps>}

            {Item && Item.orderStatus === 'ACCEPTED' && <Steps size={'small'} progressDot={true} current={Status}>
                <Steps.Step title={'Pending'} description={moment(Item.acceptedInstant).format('MMMM Do YYYY')} />
                <Steps.Step title={'Partially'}
                    description={moment(Item.partiallyDeliveredInstant).format('MMMM Do YYYY')} />
                <Steps.Step title={'Delivered'} description={moment(Item.deliveredInstant).format('MMMM Do YYYY')} />
            </Steps>}

            {Item && (
                <TabSet
                    routes={{
                        default: 'items',
                        routes: [
                            {
                                permission: 'order:list',
                                name: 'Items',
                                dataIndex: 'items',
                                component: visible => <OrderItems visible={visible} parent={Item} />
                            },
                        ],
                    }}
                />
            )}
        </MainPage>
    );
}

export default Order