import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Spin, Tabs } from 'antd';
import PathHandler from '../util/PathHandler';

const TabSet = ({ routes }) => {
  const state = useSelector((state) => state.profile.user);

  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const [Routes, setRoutes] = useState();

  const [activeKey, setActiveKey] = useState(
    PathHandler(location, routes.pathSection || 4, routes.default)
  );

  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState();

  useEffect(() => {
    if (state) {
      setLoading(false);
      setRoles(
        state && state.resource_access.sdm && state.resource_access.sdm.roles
      );
    }
  }, [state]);

  useEffect(() => {
    setRoutes(routes);
  }, [routes]);

  return (
    <>
      {!loading ? (
        <Tabs
          animated={true}
          tabBarStyle={{
            margin: '0 15px',
          }}
          activeKey={PathHandler(
            location,
            routes.pathSection || 4,
            routes.default
          )}
          onChange={(key) => {
            setActiveKey(key);
            history.push(`${url}/${key}`);
          }}
        >
          {Routes &&
            Routes.routes.map(
              (route) =>
                route.permission &&
                roles &&
                (roles.includes(route.permission) ||
                  roles.includes('super-user')) && (
                  <Tabs.TabPane tab={route.name} key={route.dataIndex}>
                    {route.component(activeKey === route.dataIndex)}
                  </Tabs.TabPane>
                )
            )}
        </Tabs>
      ) : (
        <Spin />
      )}
    </>
  );
};

export default TabSet;
