import {
  listOrganizationClientsRecursiveClientsForOrganization,
  organizationApis,
  parentRouteLocationApis,
} from '../api/api';
import DataTable from '../core/DataTable/DataTable';
import { useDispatch } from 'react-redux';
import { setRoutes } from '../app/markerReducer';
import { Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import ErrorHandler from '../util/ErrorHandler';
import { useHistory } from 'react-router-dom';

const Locations = ({ parent }) => {
  const history = useHistory();
  const dispatch = useDispatch();


  const addToMap = (data) => {
    let routes = data.content.map((routeLocation) => ({
      ...routeLocation.location,
    }));

    dispatch(setRoutes(routes));
  };

  return (
    <Spin spinning={false}>
      <DataTable
        browse={(record) =>
          history.push(`/organization/${record.organization.id}`)
        }
        deletable={(record) => true}
        editable={true}
        defaultFilters={{
          route: {
            id: parent.parentRoute.id,
          },
          modifyChildren: false,
        }}
        initialValues={{
          modifyChildren: false,
        }}
        apis={parentRouteLocationApis}
        dataProcessor={addToMap}
        drag={true}
        parent={parent}
        itemName={'parent_route_location'}
        formToRestMapper={(data) => ({
          ...data,
          modifyChildren: data.modifyChildren ? true : false,
          organization: undefined,
        })}
        columns={[
          {
            title: 'Name',
            dataIndex: ['location', 'id'],
            persistedDataIndex: ['organization', 'name'],
            type: 'select',
            editable: (record) => record.temp,
            fetch: (searchText) =>
              organizationApis.listApi({
                pageable: {
                  page: 0,
                  size: 10,
                  sort: 'lastModifiedDate',
                  direction: 'DESC',
                },
                entity: {
                  name: searchText,
                },
              }),
            fetchToItems: (response) =>
              response.data.content.map((item) => (
                <Select.Option value={item?.location?.id}>
                  {item.name}
                </Select.Option>
              )),
          },
          {
            title: 'Modify Children',
            dataIndex: ['modifyChildren'],
            type: 'checkbox',
            editable: (record) => true,
          },
        ]}
      />
    </Spin>
  );
};

export default Locations;
